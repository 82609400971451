






































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

@Component
export default class CreditsViewer extends Vue {
  @Prop({ default: false })
  displayHelp!: boolean;

  @Prop()
  private userId: string | undefined;

  private q_description: any = {
    P2T: "DeSI-Talents",
    P2T_FREE: "DeSI-P2T Gestion des Talents (gratuit)",
    P2T_OPT1: "DeSI-P2T Gestion des Talents (Option 1)",
    P2T_OPT2: "DeSI-P2T Gestion des Talents (Option 2)",
    P2T_OPT3: "DeSI-P2T Gestion des Talents (Option 3)",
    P2R: "DeSI-P2R Recrutement",
    P7: "DeSI-P7 Coaching",
    "P7+8": "DeSI-P7+8 Orientation",
    S2: "DeSI-S2",
    S7: "DeSI-S7 Coaching",
    "S7+8": "DeSI-S7+8 Orientation",
    "DeSI-ACTIV": "DeSI-Activités",
    "Evaluation Neuroscience": "Evaluation Neuroscience"
  };

  private credits: Array<any> = [];

  fetchData() {
    console.log("fetch data");
    axios
      .get("/api/balance", { params: { userId: this.userId } })
      .then(ans => {
        this.credits = ans.data;
      })
      .catch(console.log);
  }

  @Watch("userId")
  userIdChanged(v) {
    if (v) {
      this.fetchData();
    }
  }

  mounted() {
    if (this.userId) {
      this.fetchData();
    }
  }
}
