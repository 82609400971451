


















































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import { CommentData } from "@/types";
import { mapGetters } from "vuex";
import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

interface CertificatCommentsEdition{
  comment: CommentData;
  isNew: boolean;
  saved: boolean;
}

@Component({components: {}, computed: mapGetters(["getUser"])})

export default class Certificat_comments extends Vue {

    comments : CommentData[] = [];
    currentConditionSCOPE: string = "";
    currentConditionVALUE: string = "";
    condition_Options : string[] = ["T = ", "T > ", "T >= ", "P = ", "P > ", "P >= ", "( P + T ) = ", "( P + T ) > ", "( P + T ) >= "];

    currentComment: CertificatCommentsEdition = {
        comment: {
            id: "",
            comment_content: "",
            conditions: [],

        },
        isNew: true,
        saved: true,
    }

    newComment(){
        this.currentComment = {
            comment: {
                id: "",
                comment_content: "",
                conditions: [],

            },
            isNew: true,
            saved: true,
        };
        this.currentConditionSCOPE = "";
    }

    get scope_opts(){
        const defaultval: any[] = [{text: "Sélectionner la portée de la condition", value: '', disabled: true}];
        return defaultval.concat(this.condition_Options.map(e => {
        return {
            text: e.replace('- ', ''),
            value: e,
            disabled: this.currentComment.comment.conditions.filter(cond => cond.scopeCond[0]==e[0]).length
        }
        }))
    }

    addCondition(){
        if(this.currentConditionSCOPE){
            this.currentComment.comment.conditions.push({scopeCond: this.currentConditionSCOPE, valueCond: 0})
            this.currentConditionSCOPE ='';
        }
    }

    removeCondition(e){
        this.currentComment.comment.conditions = this.currentComment.comment.conditions.filter(ee => ee.scopeCond != e.scopeCond)
    }

    saveComment(){

        axios.post('/api/certificat_comments', {data: this.currentComment.comment, isNew: this.currentComment.isNew})
        .then(() => {

            this.fetchComments().then(() => {

                this.newComment();
                this.fetchComments();
                this.$toast.success('Condition ajouté avec succès!')


            })
            .catch((err) => {
                console.log(err.response)
                this.$toast.error('Une erreur est survenue')
            });

        })
        .catch(() => {
            this.$toast.error('Une erreur est survenue')
        })
    }

    deleteComment(){

        axios.delete('/api/certificat_comments', {params: {id: this.currentComment.comment.id}})
        .then(() => {
            this.$toast.success('Commetaire supprimé!')
            this.fetchComments();
            this.newComment();
        }
        ).catch(console.log)
    }

    resetComment(){
        this.newComment();
    }


    setCurrentComment(e){
        this.currentComment = {
            comment : e,
            isNew: false,
            saved: true,            
        }
    }

    fetchComments(){
        return axios.get('/api/certificat_comments')
        .then(resp => {
            this.comments = resp.data
            console.log(this.comments)
        })
        .catch(console.log)
    }

    mounted(){
        this.fetchComments();
    }
    
    @Watch('currentComment.comment', {deep: true})
    cjchgd(){
        this.currentComment.saved = false;
    }

}
