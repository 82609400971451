


















import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: { ValidationProvider }
})
export default class FormElement extends Vue {
  @Inject("$validator") public $validator!: any;

  @Prop()
  form!: any;

  @Prop({ default: "3" })
  colclass!: any;

  @Prop()
  labelclass!: any;

  @Prop()
  formclass!: any;

  @Prop({ default: "required" })
  rules!: any;

  @Prop()
  label!: string;
}
