import { render, staticRenderFns } from "./JobBoardFormSavoirFaireSection.vue?vue&type=template&id=5e7d42c8&scoped=true&"
import script from "./JobBoardFormSavoirFaireSection.vue?vue&type=script&lang=ts&"
export * from "./JobBoardFormSavoirFaireSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7d42c8",
  null
  
)

export default component.exports