











































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { User, Privileges } from "@/types";
import CreditsManager from "@/components/CreditsManager.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

interface TxOriginUser {
  userId: string;
}

interface TxOriginPaid {
  id: string;
}

type TxOrigin = TxOriginUser | TxOriginPaid;

interface Transaction {
  amount: number;
  dest: string;
  id: string;
  origin: TxOrigin;
}

interface UserObj {
  data: User;
  backup: User;
  editing: boolean;
  isNew: boolean;
  hovered: boolean;
  transactions: Transaction[];
}

interface UserF {
  id: string | null;
  company: string | null;
  privileges: Privileges | null;
  resume: boolean | null;
}

@Component({
  components: { CreditsManager },
  computed: mapGetters(["getUser"])
})
export default class UserManagement extends Vue {
  @Prop({ default: false })
  displayHelp!: boolean;
  
  @Prop({ default: "all" })
  userType!: string;

  @Prop({ default: "" })
  loggedInUser!: string; //loggedInUser

  @Prop({ default: "Gestion des utilisateurs" })
  title!: string;

  activationMailSendResult = "";
  getUser: any;
  private newUser: UserObj = {
    data: {
      id: "",
      company: "",
      privileges: Privileges.enduser,
      active: false,
      resume: false,
      created: new Date(),
      modified: new Date()
    },
    backup: {
      id: "",
      company: "",
      privileges: Privileges.enduser,
      active: false,
      resume: false,
      created: new Date(),
      modified: new Date()
    },
    hovered: false,
    editing: false,
    isNew: true,
    transactions: []
  };

  userFilter: UserF = {
    id: null,
    privileges: null,
    company: null,
    resume: null
  };

  privileges_opts: any = [
    { value: null, text: "Veuillez sélectionner une option" },
    { value: Privileges.enduser, text: "Répondant" },
    { value: "client", text: "Client" },
    { value: "admin", text: "Administrateur" }
  ];
  
  get companyFilterOpts(){
    return [{value: null, text: "entreprise"}].concat(
      (!this.users)?[]:_.sortBy(_.uniq(this.users.map(e => e.data.company).filter(e => e))
      .map(e => {return {value: e, text: e}})
    , [e => e.text]))
  }
  
  private users: Array<UserObj> = [];
  private selected: UserObj | null = null;
  selectU(a) {
    this.selected = a;
  }

  get filteredUsers() {
    return this.users.filter(this.userFacetteOk);
  }

  userFacetteOk(u) {
    return (
      (this.userFilter.id == null || this.facetteIdRegeps.test(u.data.id)) &&
      (this.userFilter.company == null ||
       this.facetteCompanyRegeps.test(u.data.company)) &&
      (this.userFilter.privileges == null ||
       this.userFilter.privileges == u.data.privileges) &&
      (this.userFilter.resume == null ||
       this.userFilter.resume == u.data.resume)
    );
  }

  hoverUser(a, b) {
    a.hovered = b;
  }

  get facetteIdRegeps() {
    return !this.userFilter.id
         ? new RegExp("", "i")
         : new RegExp(this.userFilter.id, "i");
  }
  get facetteCompanyRegeps() {
    return !this.userFilter.company
         ? new RegExp("", "i")
         : new RegExp(this.userFilter.company, "i");
  }

  createUser() {
    this.selected = this.newUser;
    this.selected.editing = true;
    this.selected.isNew = true;
  }

  deleteUser(id) {
    axios
    .delete("/user", { params: { id: id } })
    .then(() => {
      this.getUsers();
      this.selected = null;
    })
    .catch(console.log);
  }

  updateUser(u: UserObj) {
    axios
    .post(u.isNew ? "/create-user" : "/user", u.data)
    .then(() => {
      this.getUsers();
      u.editing = false;
      if (u.isNew) {
        this.sendActivation(u);
      }
    })
    .catch(err => {
      console.log(err);
      let errmsg = err.response.data;
      if (errmsg.includes("already")) {
        errmsg = "L'utilisateur existe déjà !";
      }
      this.$toast.error(errmsg);
    });
  }

  resetFilter() {
    this.userFilter = {
      id: null,
      company: null,
      privileges: null,
      resume: null
    };
  }

  resetUser(u) {
    u.data = JSON.parse(JSON.stringify(u.backup));
    u.editing = false;
    this.selected = null;
  }

  editUser(u) {
    u.backup = JSON.parse(JSON.stringify(u.data));
    u.editing = true;
  }

  sendActivation(u) {
    axios
    .post("/api/activate-user", u.data)
    .then(resp => {
      console.log(resp);
      this.activationMailSendResult = "email envoyé!";
    })
    .catch(err => {
      console.log(err);
      this.activationMailSendResult = err.data;
    });
  }

  getUsers() {
    axios
    .get("/api/users")
    .then(resp => {
      console.log(resp.data);
      this.users = _.sortBy(
        resp.data
        .filter(u => this.userType.includes(u.privileges))
        .map(u => {
          u.created = new Date(u.created);
          u.modified = new Date(u.modified);
          return {
            data: u,
            backup: JSON.parse(JSON.stringify(u)),
            editing: false,
            isNew: false
          };
        }),
        [e => -e.data.created.getTime()]
      );

      console.log(resp);
    })
    .catch(console.log);
  }

  @Watch("selected.data.id")
  selectedChanged(id) {
    if (this.selected && !this.selected.isNew) {
      axios
      .get("api/transactions", { params: { user: id } })
      .then(ans => {
        console.log("the transaction list is:");
        console.log(ans.data);
        const tx = ans.data.map(e => {
          console.log(e.txdate);
          try {
            e.txdate = new Date(e.txdate);
          } catch (err) {
            e.txdate = null;
          }
          return e;
        });
        console.log("TRANSACTIONS for", id);
        console.log((this.selected as any).transactions);
        console.log(this.selected);
        this.$set(this.selected as UserObj, "transactions", tx);
      })
      .catch(console.log);
    }
  }

  mounted() {
    this.getUsers();
    console.log("---------->");
    console.log(this.getUser);
    if (this.getUser.admin == false) {
      this.newUser.data.company = this.getUser.company;
      this.newUser.data.privileges = Privileges.enduser;
    }
  }
}
