









import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/NavBar.vue";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {
    NavBar
  }
})
export default class App extends Vue {
  url = process.env.BASE_URL;
  mounted() {
    axios
      .post("/login", {})
      .then(resp => {
        
        this.$store
          .dispatch("setUser", resp.data)
          .then()
          .catch();
        this.$store.dispatch("setLoggedIn", true);
      })
      .catch(() => {
        this.$store.dispatch("setLoggedIn", false);
      });
  }
}
