var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticStyle:{"width":"100%"},attrs:{"fluid":""}},[_c('b-row',[_c('JobMatchingEditor')],1),_c('b-row',[_c('SoftSkillsEditor')],1),_c('b-row',[_c('h4',[_vm._v("Secteurs")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'secteurs' },"itemFields":['secteur'],"itemsPerPage":25,"editable":true}}),_c('h4',[_vm._v("Métiers")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'jobs' },"itemFields":[
        'secteur',
        'famille_metier',
        'metier',
        'duree_etudes',
        'autonomie',
        'si_tension',
        'pcd' ],"itemsPerPage":25,"editable":true}}),_c('h4',[_vm._v("Entreprises")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'entreprises' },"itemFields":['entreprise'],"itemsPerPage":25,"editable":true}}),_c('h4',[_vm._v("Atypismes")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'atypismes' },"itemFields":['atypisme'],"itemsPerPage":25,"editable":true}}),_c('h4',[_vm._v("Les familles des postes")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'JobMatchingFamilies' },"itemFields":['familles_poste'],"itemsPerPage":25,"editable":true}}),_c('h4',[_vm._v("Les liste des valeurs possibles de la durée d'études requise pour un poste")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'duree_etudes' },"itemFields":['duree_etudes'],"itemsPerPage":25,"editable":true}}),_c('h4',[_vm._v("Les liste des valeurs possibles de l'autonomie pour un poste")]),_c('PaginatedViewer',{attrs:{"endpoint":"/api/open-metadata","params":{ view: 'autonomie' },"itemFields":['autonomie'],"itemsPerPage":25,"editable":true}})],1),_c('b-row',[_c('Certificat_comments')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }