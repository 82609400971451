

























































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import PaginatedViewer from "@/components/PaginatedViewer.vue";
import Certificat_comments from "@/components/Certificat_comments.vue";
import JobMatchingEditor from "@/components/JobMatchingEditor.vue";
import SoftSkillsEditor from "@/components/SoftSkillsEditor.vue";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {
    PaginatedViewer,
    JobMatchingEditor,
    Certificat_comments,
    SoftSkillsEditor
  }
})
export default class JobMetadataUpdater extends Vue {}
