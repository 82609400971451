export interface WBCell {
  coords: string;
  content: string | number;
}

export interface Question {
  title: WBCell;
  answer: WBCell;
}

export interface QuestionnaireSection {
  title: WBCell;
  questions: Array<Question>;
}

export interface RadioChoice {
  title: WBCell;
  choice: WBCell;
}

export interface RadioQuestion {
  title: WBCell;
  choices: Array<RadioChoice>;
  choice: number;
}

export interface RadioFieldChoice {
  title: WBCell;
  choice: number;
}

export interface RadioFieldChoiceBilateral extends RadioFieldChoice {
  titleRight: WBCell;
}

export interface QuestionnaireSectionRadioField {
  title: WBCell;
  choices: Array<WBCell>;
  questions: Array<RadioFieldChoice>;
}

export type Qsection = QuestionnaireSection | QuestionnaireSectionRadioField;

export interface QuestionnaireData {
  title: WBCell;
  sections: Array<Qsection>;
}

////////////////////////////

export interface Identity {
  name: string;
  forname: string;
}

export enum RecordStatus {
  available = "available",
  toFinish = "toFinish",
  finished = "finished"
}

interface RecordMeta {
  hovered: boolean;
  isOpen: boolean;
  reloadingTxt: string;
}

export interface Record {
  available: boolean;
  creationDate: Date | null;
  modificationDate: Date | null;
  identity: Identity;
  qtype: string;
  status: RecordStatus;
  meta: RecordMeta;
}

export interface Engine {
  added: Date;
  id: string;
}

export enum CoverPageType{
  desi_talents = "DeSI-Talents",
}

export interface Cover {
  added: Date;
  id: string;
  coverPageType: CoverPageType;
}

export interface UploadInfo {
  started: Date;
  transferred: number;
  toTransfer: number;
}

export enum Privileges {
  admin = "admin",
  enduser = "enduser",
  client = "client"
}

export interface User {
  id: string;
  privileges: Privileges;
  company: string;
  active: boolean;
  resume: boolean;
  created: Date;
  modified: Date;
}

interface Customer {
  email: string;
}

export interface Order {
  amount: number;
  currency: string;
  orderId: string;
  customer: Customer;
}

export interface Experience {
  area: string;
  years: number;
}

export interface FormSavoirFaire {
  degrees: Array<string>;
  desiredStatus: Array<string>;
  description: string;
  jobsAndExperiences: Array<string>;
  desiredJobs: Array<string>;
  desiredAreas: Array<string>;
  needReorientation: boolean;
  areasExperiences: Array<string>;
  areasExperiencesYears: Array<Experience>;
}

export interface Employeur {
  entreprise: string;
  group: string;
}

export interface FormSavoirEtre {
  atypismes: Array<string>;
  others: string;
  dreams: string;
}

export interface FormJob {
  idealJob: Array<string>;
  idealArea: Array<string>;
}

export interface FormEntreprise {
  desiredEmployeur: Array<string>;
  currentOrPastEmployeurs: Array<Employeur>;
}

export interface Disponibilite {
  type: string;
  date: string | null;
  delai: number | null;
}

export interface RegisterForm {
  forname: string;
  name: string;
  email: string;
  phone: string;
  ddn: string;
  disponibilite: Disponibilite;
  genre: string;
  cp: string;
  mobilite: any[];
  resumeMetadata: ResumeData | null;
  savoirFaire: FormSavoirFaire;
  savoirEtre: FormSavoirEtre;
  job: FormJob;
  entreprise: FormEntreprise;
}

export interface ResumeData {
  sha: string;
  destfile: string;
  filename: string;
}

interface JobSS{
    name: string;
    val: number;
}

export interface JobMatching{
    name: string;
    creator: string;
    id: string;
    family: string;
    durations: string[];
    autonomies: string[];
    JM_tension: boolean;
    val: JobSS[];
    created: Date | null;
    modified: Date | null;
}

interface ConditionCMT{
scopeCond : string;
valueCond: number;
}

export interface CommentData{
  id: string;
  comment_content: string;
  conditions: ConditionCMT[],
}