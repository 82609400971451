

























































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
import Paiement from "@/components/Paiement.vue";
import { Order } from "@/types";

declare global {
  interface Window {
    KR: any;
  }
}

interface Price {
  name: string;
  value: number;
}

@Component({
  components: { Paiement },
  computed: mapGetters(["getUser"])
})
export default class PaiementTest extends Vue {
  showForm = false;
  errormsg = "";
  infomsg = "";
  amount = 8.88;
  getUser: any;
  paiements: Array<Paiement> = [];

  depouillements: Array<Price> = [];
  selectedDepouillement = "";
  order: Order | null = null;
  mode = "test";

  get depouillementOptions() {
    return [{ text: "Sélectionner un dépouillement", value: "" }].concat(
      this.depouillements.map(e => {
        const txt = e.name + " (" + e.value + "€ )";
        return {
          text: txt,
          value: e.name
        };
      })
    );
  }

  get orderDetails() {
    return {
      type: this.selectedDepouillement,
      description: this.selectedDepouillement
    };
  }

  fetchPaiements() {
    axios
      .get("/api/paiements")
      .then(resp => {
        this.paiements = resp.data.map(e => {
          e.amount = Number((e.amount / 100).toFixed(2));
          e.date = new Date(e.date);
          return e;
        });
      })
      .catch(console.log);
  }

  onPaiementSuccess() {
    this.fetchPaiements();
  }

  onPaiementError() {
    this.$toast.error("Le paiement n'a pas pu aboutir, veuillez recommencer.");
  }

  valider() {
    axios
      .get("/api/create-paiement", {
        params: { type: this.selectedDepouillement }
      })
      .then(resp => {
        console.log("paiement created");
        console.log(resp.data);
        this.order = {
          orderId: resp.data.id,
          amount: resp.data.amount,
          customer: {
            email: this.getUser.id
          },
          currency: resp.data.currency
        };
      })
      .catch(console.log);
  }

  mounted() {
    this.fetchPaiements();
    axios
      .get("/api/prices")
      .then(resp => {
        this.depouillements = Object.entries(resp.data)
          .filter(e => e[1])
          .map(e => {
            const val = e[1] as number;
            return {
              name: e[0],
              value: Number((val / 100).toFixed(2))
            };
          });
      })
      .catch(console.log);
  }
}
