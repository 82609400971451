







































































































import { Component, Vue, Prop  } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";

import _ from "lodash";


axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  computed: mapGetters(["getUser"]),
})


export default class NeuroscienceEvaluationScores extends Vue{


    qId = "";
    infomsg = "";
    scores: any = {};
    baseurl = process.env.BASE_URL;

    dominateBrain:any = {};
    supportiveBrain: any = {};
    
    CombinaisonBrain: any = {};

    dominantSupportiveBrains: any = {};
    supportBrains: any = {};

    fetch_data(){
        axios
        .get("/api/depouillateNeuroscienceEvaluation", {params: {id: this.qId}})
        .then(ans => {
            
            this.infomsg = "";
            this.scores = ans.data.scores;
            

            const dictEntries = Object.entries(this.scores.dominantSupportiveBrains as Record<string, any>)
            const sortedEntries = dictEntries.sort((a, b) => b[1]['total_n_per_A'] - a[1]['total_n_per_A'])
            this.dominantSupportiveBrains = Object.fromEntries(sortedEntries)

            //this.dominantSupportiveBrains = this.scores.dominantSupportiveBrains;
            console.log(this.dominantSupportiveBrains)

            this.dominateBrain = Object.keys(this.dominantSupportiveBrains)[0];
            this.supportiveBrain = Object.keys(this.dominantSupportiveBrains)[1];

            this.supportBrains = this.scores.supportBrains;

        })
        .catch(err => {
            this.$toast.error("Oops, le chargement a échoué !");
        });

        axios
        .get("/api/CombinaisonBrain")
        .then(ans => {
            this.CombinaisonBrain = ans.data;
        })

    }


    mounted(){

        this.qId = (this.$route.query.id as string) || "";
        this.fetch_data();
    }
}
