







































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import { mapGetters } from "vuex";
import JobMatchingSelector from "@/components/JobMatchingSelector.vue";

import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

interface CreditTypesSelect {
  text: string;
  value: string;
  disabled: boolean;
}

@Component({
  components: { JobMatchingSelector },
  computed: mapGetters(["getUser"])
})
export default class CreditsViewer extends Vue {
  @Prop()
  private userId: string | undefined;

  @Prop()
  private privileges: string | undefined;
  
  getUser: any;
  addMsg = "ajouter";
  
  @Prop({ default: () => [] })
  transactions!: any[];

  found: boolean = false;
  DeSI_talents_list: Array<any> = [];
  selectedOption: string = "";
  p2tTxId: string = "";
  activTxId: string = "";
  type: string = "";


  get disableNewP2T () {
    return this.getUser.privileges == "admin" || this.getUser.credits["P2T"] > 0;
  }

  get sendOptions() {

    if (this.DeSI_talents_list.length > 0) {
        // Si OUI
        return [{ text: "Selectionner une option :", value: "" }, { text: " Nouveau DeSI-Talents", value: "desiPremium", disabled: !this.disableNewP2T }].concat(
          this.DeSI_talents_list
            .map(e => {
              return {
                text: e.identity.forname.concat(" " + e.identity.name + ". Envoyé le : "+ e.referrer.txdate),
                value: e.id,
                disabled: false
              };
            })
        );      
    }
    else {
        // SI NON
        return [{ text: "Selectionner une option :", value: "" }, 
                { text: " Envoyer DeSI-Activités seulement", value: "only"}, 
                { text: " Envoyer DeSI-Orientation-Premium", value: "desiPremium"}] 

    }

  }

  selectedJob: string | null = null;

  private q_description: any = {
    P2T: "DeSI-Talents",
    P2T_FREE: "DeSI-P2T Gestion des Talents (gratuit)",
    P2T_OPT1: "DeSI-P2T Gestion des Talents (Option 1)",
    P2T_OPT2: "DeSI-P2T Gestion des Talents (Option 2)",
    P2T_OPT3: "DeSI-P2T Gestion des Talents (Option 3)",
    P2R: "DeSI-P2R Recrutement",
    P7: "DeSI-P7 Coaching",
    "P7+8": "DeSI-P7+8 Orientation",
    S2: "DeSI-S2",
    S7: "DeSI-S7 Coaching",
    "S7+8": "DeSI-S7+8 Orientation",
    "DeSI-ACTIV": "DeSI-Activités",
    "Evaluation Neuroscience": "Evaluation Neuroscience",
  };

  get last_tx() {
    const ans = _.sortBy(this.transactions, [e => -e.type]).reduce((acc, e) => {
      if (
        e.dest != "burn" &&
        e.origin &&
        (e.origin.userId || "") == this.userId
      ) {
        acc[e.type] = e.dest;
      }
      return acc;
    }, {});
    return ans;
  }

  private credits: Array<any> = [];
  private creditTypes: Array<string> = [];
  get creditTypesOptions() {
    return [{ text: "Type de crédit :", value: "" }].concat(
      this.creditTypes
        .filter(
          e => this.getUser.privileges == "admin" || this.getUser.credits[e] > 0
        )
        .map(e => {
          return {
            text: this.q_description[e],
            value: e,
            disabled: false // (this.credits[e] > 0) && (this.privileges == 'enduser')
          };
        })
    );
  }
  private selectedCredit = "";
  private selectedAmount = 0;
  

  
  
  continueClicked(){

    this.activTxId = "";
    this.p2tTxId = "";


    if (this.selectedOption == 'only'){
      this.addCredits(1, '');
    }
    else if (this.selectedOption == 'desiPremium'){
      // create the attachement between desi-Talents and desi-Activities
      //when desi-talents responded send desi-activities
      this.addCredits(1, '');
      this.addCredits(1, 'P2T');
      this.type = "txId_txId";
      var interval = setInterval(() => {
          if( this.p2tTxId != "" && this.activTxId != ""){
            clearInterval(interval);
            
              if (this.privileges == "enduser" && this.p2tTxId != "" && this.activTxId != "")
              {
                  axios
                  .post("/api/DeSI_premium", {}, { params: { type : this.type, talentsId: this.p2tTxId, activitiesId: this.activTxId } })
                  .then(ans => {
                  })
                  .catch(console.log);
              }

              this.selectedOption = "";

          }else{
              console.log('a')
              //Do Something While Waiting / Spinner Gif etc.
          }
      }, 100);
      const amount = 1 * (this.privileges == "enduser" ? 1 : this.selectedAmount);
      
      this.$toast.success(
        "Vous avez envoyé " +
          amount +
          " crédit" +
          (amount > 1 ? "s" : "") +
          " " +
          "DeSI-Orientation-Premium" +
          " à " +
          this.userId +
          " !"
      );
    }
    else{
      // create the attachement between the existant desi-Talents and the new desi-Activities 
      this.addCredits(1, '');
      this.p2tTxId = this.selectedOption;
      this.type = "qId_txId";
      var interval = setInterval(() => {
          if( this.p2tTxId != "" && this.activTxId != ""){
            clearInterval(interval);
            
              if (this.privileges == "enduser" && this.p2tTxId != "" && this.activTxId != "")
              {
                  axios
                  .post("/api/DeSI_premium", {}, { params: { type : this.type, talentsId: this.p2tTxId, activitiesId: this.activTxId } })
                  .then(ans => {
                  })
                  .catch(console.log);
              }

              this.selectedOption = "";

          }else{
              console.log('a')
              //Do Something While Waiting / Spinner Gif etc.
          }
      }, 100);
    }

    this.$bvModal.hide('modal-5');
  }

  async handleAddCredits(factor){

    if (factor > 0 ){

      if (this.selectedCredit == 'DeSI-ACTIV'){
        
        this.$bvModal.show('modal-5');
        // looking for desi-talents for an enduser
        if (this.privileges == "enduser"){

          this.found = true;

        await axios
          .get("/api/questionnaires-metadata")
          .then(ans => {
              
              function cleanOrigin(e){
                if(e.userId){
                  return e.userId;
                }else if(e._type == "JobBoardFormCredit"){
                  return "Job-Board"
                }else{
                  return e
                }
              }
                    
              const prepare = function(e) {
                e.creationDate = new Date(e.creationDate);
                if (e.modificationDate) {
                  e.modificationDate = new Date(e.modificationDate);
                }
                e.status =
                  e.started == undefined || e.started == true
                    ? e.finished
                      ? "finished"
                      : "toFinish"
                    : "toFinish";
                e.meta = {
                  hovered: false,
                  isOpen: false,
                  reloadingTxt: false
                };
                return e;
              };

              const f = b => {
                return _.sortBy(ans.data.filter(e => (e.finished == b && e.userId == this.userId && e.referrer.origin.userId == this.getUser.id)).map(prepare), [
                  e => -e.creationDate.getTime()
                ]);
              };
              
              axios
              .get("/api/DeSI_premium")
              .then( ans2 => {
                this.DeSI_talents_list=[];
                f(true).forEach( t => {
                      var prem = false;
                      ans2.data.forEach(e => {
                        if (e.talentsId == t.id){
                          prem = true;
                        }
                      
                      });
                      if (prem == false){this.DeSI_talents_list.push(t);}                  
                  });
              })

            })
        
          this.found = false;              
        }

      }
      else {
      this.addCredits(factor, '');
      }
    }

    else {
      this.addCredits(factor, '');
    }

  }

  addCredits(factor, other) {

    if (other == 'P2T') {
        this.addMsg = "ajout en cours...";
        const amount =
          factor * (this.privileges == "enduser" ? 1 : this.selectedAmount);
          
        if (this.selectedCredit && amount) {
          axios
            .post("/api/transfer-credit", {
              creditType: 'P2T',
              amount: amount,
              dest: this.userId,
              job: this.selectedJob,
            })
          .then(ans => {
              
              this.p2tTxId = ans.data;
              if (this.selectedOption != 'desiPremium'){
              
                this.$toast.success(
                "Vous avez envoyé " +
                  amount +
                  " crédit" +
                  (amount > 1 ? "s" : "") +
                  " " +
                  "P2T" +
                  " à " +
                  this.userId +
                  " !"
              );}


              this.addMsg = "ajouter";
              this.fetchData();

            })
            .catch(err => {
              this.addMsg = "ajouter";
              console.log(err);
              
              let reason = err.response.data.reason;
              if (reason.includes("insufficient")) {
                reason =
                  "vous ne disposez pas de suffisament de crédits pour ce type de questionnaire !";
              }
              this.$toast.error(reason);
            });
        }      

    }
    else{
        this.addMsg = "ajout en cours...";
        const amount =
          factor * (this.privileges == "enduser" ? 1 : this.selectedAmount);
        if (this.selectedCredit && amount) {
          axios
            .post("/api/transfer-credit", {
              creditType: this.selectedCredit,
              amount: amount,
              dest: this.userId,
              job: this.selectedJob,
            })
          .then(ans => {
              
              if (this.selectedCredit == "DeSI-ACTIV"){this.activTxId = ans.data;}
              if (this.selectedOption != 'desiPremium'){
              
                this.$toast.success(
                "Vous avez envoyé " +
                  amount +
                  " crédit" +
                  (amount > 1 ? "s" : "") +
                  " " +
                  this.selectedCredit +
                  " à " +
                  this.userId +
                  " !"
              );}


              this.addMsg = "ajouter";
              this.fetchData();

            })
            .catch(err => {
              this.addMsg = "ajouter";
              
              console.log(err);
              
              let reason = err.response.data.reason;
              if (reason.includes("insufficient")) {
                reason =
                  "vous ne disposez pas de suffisament de crédits pour ce type de questionnaire !";
              }
              this.$toast.error(reason);
            });
        }      
    }
  }

  fetchData() {
    console.log("fetch data");
    axios
      .get("/api/balance", { params: { userId: this.userId } })
      .then(ans => {
        this.credits = ans.data;
      })
      .catch(console.log);
  }

  @Watch("userId")
  userIdChanged(v) {
    if (v) {
      this.fetchData();
    }
  }

  mounted() {
    if (this.userId) {
      this.fetchData();
    }
    /*axios
      .get("api/credit-types")
      .then(ans => {
        console.log('credit-types');
        console.log(ans.data);
        this.creditTypes = ans.data
        // .filter(e => !e.includes("_"));
      })
      .catch(console.log);*/

      this.creditTypes = [
          "S2",
          "S7",
          "P7",
          "S7+8",
          "P2R",
          "P2T",
          "DeSI-ACTIV",
          "Evaluation Neuroscience"]
  }
}
