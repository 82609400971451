















































































































































































































































































































import { Component, Vue, Watch, Provide } from "vue-property-decorator";
import JsonViewer from "vue-json-viewer/ssr";
import axios from "axios";
import { RegisterForm } from "@/types";
import FileUploader from "@/components/FileUploader.vue";
import LocationExplorer from "@/components/LocationExplorer.vue";
import FormElement from "@/components/jobboard/FormElement.vue";
import FormElementTransition from "@/components/jobboard/FormElementTransition.vue";
import JobBoardFormSavoirFaireSection from "@/components/jobboard/JobBoardFormSavoirFaireSection.vue";
import JobBoardFormSavoirEtreSection from "@/components/jobboard/JobBoardFormSavoirEtreSection.vue";
import JobBoardFormJobSection from "@/components/jobboard/JobBoardFormJobSection.vue";
import JobBoardFormEntrepriseSection from "@/components/jobboard/JobBoardFormEntrepriseSection.vue";

import PaginatedViewer from "@/components/PaginatedViewer.vue";

import { mapGetters } from "vuex";
import { VueTelInput } from "vue-tel-input";

import {
  ValidationProvider,
  setInteractionMode,
  ValidationObserver
} from "vee-validate";
import { extend } from "vee-validate";
import { configure } from "vee-validate";

configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: "is-dirty"
  }
});
setInteractionMode("passive");

axios.defaults.baseURL = process.env.BASE_URL;

extend("required", {
  validate(value) {
    return {
      required: true,
      valid:
        ["", null, undefined].indexOf(value) === -1 &&
        !(value.constructor == Array && value.length == 0)
    };
  },
  computesRequired: true
});

interface HelpData {
  emailOk: boolean;
  telOk: boolean;
  email: string;
  tel: string;
}

@Component({
  components: {
    FileUploader,
    JsonViewer,
    PaginatedViewer,
    VueTelInput,
    LocationExplorer,
    ValidationProvider,
    ValidationObserver,
    FormElement,
    FormElementTransition,
    JobBoardFormSavoirFaireSection,
    JobBoardFormSavoirEtreSection,
    JobBoardFormJobSection,
    JobBoardFormEntrepriseSection,
  },
  computed: mapGetters(["getUser"]),
  $_veeValidate: { validator: "new" }
})
export default class JobBoard extends Vue {
  triedValidating = false;
  @Provide("$validator") public $validator!: any;
  getUser: any;
  helpData : HelpData = {
    emailOk: true,
    telOk: false,
    email: "",
    tel: "",
  }
  showHelpModal = false;
  problemDescription = "";
  viewId: string | null = null;
  dispoDateDelai = 1;
  
  get restrictEdit(){
    return (!!this.viewId) && (!this.viewId.includes("mikaeldusenne"))  && (!this.viewId.includes("daveze"))
  }
  
  
  test: any | undefined = undefined;
  genreOpts: any[] = [
    {
      text: "Sélectionner",
      value: ""
    },
    {
      text: "Homme",
      value: "homme"
    },
    {
      text: "Femme",
      value: "femme"
    },
    {
      text: "Non-binaire",
      value: "non-binaire"
    },
    {
      text: "Ne souhaite pas répondre",
      value: "undisclosed"
    }
  ];

  employeurs: Array<string> = [];
  employeursPast: Array<string> = [];
  newMobilite: any = [];

  form: RegisterForm | null = null;
  resetForm() {
    this.form = {
      forname: "",
      name: "",
      email: "",
      ddn: "",
      disponibilite: {type: "date", date: null, delai: null},
      genre: "",
      phone: "",
      cp: "",
      mobilite: [],
      resumeMetadata: null,
      savoirFaire: {
        desiredStatus: [],
        degrees: [],
        description: "",
        jobsAndExperiences: [],
        desiredJobs: [],
        desiredAreas: [],
        needReorientation: false,
        areasExperiences: [],
        areasExperiencesYears: []
      },
      savoirEtre: {
        atypismes: [],
        others: "",
        dreams: ""
      },
      job: {
        idealJob: [],
        idealArea: [],
      },
      entreprise: {
        desiredEmployeur: [],
        currentOrPastEmployeurs: []
      }
    };
  }
  
  getHelp(){
    if(this.form){
      this.helpData.tel = this.form.phone;
      this.helpData.email = this.form.email;
      this.showHelpModal = true;
    }
  }
  
  closeHelpModal() {
    this.showHelpModal = false;
  }
  
  
  get dispAge(){
    if(!this.form) return "";
    
    const dtdiff = Date.now() - new Date(Date.parse(this.form.ddn)).getTime();
    const age = Math.abs(new Date(dtdiff).getUTCFullYear()-1970);
    return !this.form.ddn?'':(' (' + age + ' ans)')
  }
  
  handleHelpOK() {
    if(this.problemDescription.length > 5000){
      this.$toast.error("texte trop long!");
      return;
    }
    
    let contactd : any = {}
    if(this.helpData.emailOk){
      contactd.email = this.helpData.email;
    }
    if(this.helpData.telOk){
      contactd.tel = this.helpData.tel;
    }
    
    axios
    .post("/api/help", {
      source: "jobboard",
      form: this.form,
      contactData: contactd,
      description: this.problemDescription,
    })
    .then(resp => {
      console.log(resp);
      this.$toast.info(
        "Votre demande d'aide a été envoyée. Nous vous contacterons par email dans les plus brefs délais."
      );
      this.closeHelpModal();
    })
    .catch(err => {
      console.log(err);
      this.$toast.error(err);
      this.closeHelpModal();
    });
  }

  formIsSending = false;
  
  sendForm() {
    this.formIsSending = true;
    console.log("validating form...");
    (this.$refs.form as Vue & { validate: () => Promise<any> })
      .validate()
    .then(result => {
      this.formIsSending = false;
      console.log(result);
      if (result) {
          axios
            .post("/api/register-job-board", {
              data: this.form,
              new: !this.viewId
            })
            .then(ans => {
              console.log("uploaded");
              console.log(ans);
              this.$toast.success(
                this.viewId ? "Modifications enregistrées !" : "Compte créé ! Veuillez consulter vos emails pour activer votre compte."
              );
            })
            .catch(err => {
              this.$toast.error(err.response.data);
            });
          console.log("Form OK");
        } else {
          console.log("Form NOT OK");
          this.$toast.error(
            "Veuillez répondre à toutes les questions requises."
          );
          console.log(result);
        }
      })
    .catch(err => {
      this.formIsSending = false;
      console.log(err)
    });
  }

  @Watch("getUser", { deep: true })
  userchgd(v) {
    if (v) {
      this.viewId = this.$route.query.id || v.id;
    }
    if (this.$route.query.id) {
      this.viewId = (this.$route.query.id || "") as string;
    }
    this.fetchData();
  }
  
  dateToInputDate(e){
    const dt = new Date(e);
    const y = dt.getFullYear();
    const m = dt.getMonth() < 10 ? `0${dt.getMonth()}` : dt.getMonth();
    const d = dt.getDate() < 10 ? `0${dt.getDate()}` : dt.getDate();
    return `${y}-${m}-${d}`;
  }
  
  fetchData() {
    axios
      .get("/api/job-board", { params: { userId: this.viewId } })
      .then(resp => {
        if (resp.data) {
          console.log("received form:")
          console.log(resp.data)
          this.form = resp.data;
          (this.form as RegisterForm).ddn = this.dateToInputDate((this.form as RegisterForm).ddn);
          (this.form as RegisterForm).disponibilite.date = this.dateToInputDate((this.form as RegisterForm).disponibilite.date);
          console.log('fetched data ok')
        }
      })
      .catch(err => {
        // console.log(err);
        if (!~[401, 404].indexOf(err.response.status)) {
          console.log(err);
          console.log(err.response.status);
        }
      });
  }

  mounted() {
    if (this.getUser) {
      console.log("logged in as " + this.getUser.id);
      this.viewId = this.getUser.id;
    }
    if (this.$route.query.id) {
      console.log("watching questionnaire of " + this.$route.query.id);
      this.viewId = (this.$route.query.id || "") as string;
    }
    const jbver = 2;
    // if (
    //   !localStorage.aloremFormJobBoardDate ||
    //   localStorage.aloremFormJobBoardDate <
    //     new Date(new Date().getTime() - 1000 * 3600 * 24 * 7)
    // ) {
    //   console.log("DELETING LOCALSTORAGE JOB FORM");
    //   delete localStorage.aloremFormJobBoard;
    // }
    if (
      !localStorage.aloremFormJobBoardVersion ||
      localStorage.aloremFormJobBoardVersion < jbver) {
      console.log("DELETING LOCALSTORAGE JOB FORM");
      delete localStorage.aloremFormJobBoard;
    }
    if (localStorage.aloremFormJobBoard) {
      console.log("LOADING LOCALSTORAGE");
      this.form = JSON.parse(localStorage.aloremFormJobBoard);
      if (this.form && typeof this.form.mobilite != "object") {
        console.log("DELETING FORM MOBILITE");
        this.form.mobilite = [];
      }
      if (this.form && ! (this.form.disponibilite && this.form.disponibilite.type) ) {
        console.log("DELETING FORM DISPONIBILITE");
        this.form.disponibilite = {type: "date", date: null, delai: null};
      }
      
      console.log("FORM DEGREES");
    } else {
      console.log("form not available in localstorage");
    }
    if (!this.form) {
      console.log("NEW FORM");
      this.resetForm();
    }

    console.log("VIEWID = " + this.viewId)
    if (this.viewId) {
      console.log('exists')
      this.resetForm();
      localStorage.setItem("aloremFormJobBoard", JSON.stringify(this.form));
      this.fetchData();
      
    }else{
      console.log('does not exists')

      setInterval(() => {
        console.log("saving localstorage")
        localStorage.aloremFormJobBoard = JSON.stringify(this.form);
        localStorage.aloremFormJobBoardDate = new Date();
        localStorage.aloremFormJobBoardVersion = jbver;
      }, 5000);
    }
  }
}
