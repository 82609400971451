



























































import { Component, Vue, Prop } from "vue-property-decorator";
import PosteMatching from "@/components/scores/PosteMatching.vue";
import PosteMatchingDetails from "@/components/scores/PosteMatchingDetails.vue";
import axios from "axios";

import _ from 'lodash';

interface Opt{
  text: string;
  value: string | null;
}

@Component({
  components: { PosteMatching, PosteMatchingDetails },
})
export default class PosteMatchingReport extends Vue {
  @Prop()
  scores: any;

  @Prop({default: () => []})
  jobs!: any[];

  @Prop({default: null})
  mainJob!: string | null;
  
  @Prop({default: ""})
  company!: string;
  
    
  generalPostsList = ["60d72b4e776c559b37241f24", 
                "60d72b9e776c559b37241f25", 
                "60d72bf58b400548a8dfaed5", 
                "60d72c52776c559b37241f26"];

  postes_general: string[] = [];


  get postes(){
    return _.sortBy(this.jobs, [e=>e.company=="Alorem", e => e.name]) //.map(e => e.name)
    // return this.scores?Object.keys(this.scores.results.postes_details):[]
  }
  
  selectedPoste: string | null = null;
  
  
  get other_postes(){
    const l : Opt[] = [{text: "Sélectionner un poste...", value: null}]
    return l.concat(this.postes
      .map(e => {return {text: `${e.company=='Alorem'?'(A) ':''}${e.name}`, value: e.name}}))
  }

  fetchGeneralPosts(){

    for(var i=0; i < this.generalPostsList.length; i++){
        
        var post = this.generalPostsList[i]
        axios
        .get("/api/sampleJobName", { params: { id: post} })
        .then(ans => {
            this.postes_general.push(ans.data.pname)
        })
        .catch(err => {
          this.$toast.error("Oops, le chargement a échoué !");
        });
    }
  }
  
  mounted(){
    if(this.mainJob){
      const p = this.postes.find(e => e.id == this.mainJob)
      this.selectedPoste = (p||{}).name;
    }else{
      this.selectedPoste = this.other_postes.length > 1 ? this.other_postes[1].value : null;
    }

    this.fetchGeneralPosts();
  }

  getSelectedPoste(){
    
    this.$store
      .dispatch("setSelectedJob", this.selectedPoste)
      .then(() => {
      })
      .catch(console.log);

  }


}
