








import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";

import _ from 'lodash';

interface Opt{
  text: string;
  value: string | null;
}

@Component({
  components: {  },
})
export default class JobMatchingSelector extends Vue {
  @Prop()
  data!: string | null;
  
  selectedPoste: string | null = null;
  jobs: any[] = [];
  
  get other_postes(){
    const l : Opt[] = [{text: "Sélectionner un poste...", value: null}]
    return l.concat(this.jobs
      .map(e => {return {text: `${e.company=='Alorem'?'(A) ':''}${e.name}`, value: e.id}}))
  }

  @Watch("data")
  datachgd(v){
    if(this.selectedPoste != v){
      this.selectedPoste = v;
    }
  }

  @Watch("selectedPoste")
  selectedchgd(v){
    if(this.selectedPoste != this.data){
      this.$emit('input', v)
    }
  }
  
  mounted(){
    axios.get("/api/job-list")
    .then(resp => {
      this.jobs = _.sortBy(resp.data, [e=>e.company=="Alorem", e => e.name])
    })
    .catch(console.log)
  }
}
