















































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";

import {
  QuestionnaireData,
  QuestionnaireSectionRadioField,
  RadioFieldChoice,
  RadioQuestion
} from "@/types";

interface BodyType {
  "q-type": string;
  data: QuestionnaireData | null;
}

axios.defaults.baseURL = process.env.BASE_URL;

// interface Metadata {
//   engine: string;
//   id: string;
// }

@Component({
  computed: mapGetters(["getUser"])
})
export default class QuestionnaireS2 extends Vue {
  isAdmin = false;
  qId: string | undefined = undefined;
  viewOnly = false;
  getUser: any;
  problemDescription = "";
  showHelpModal = false;
  shouldBackup = true;
  referrer: any = {};
  
  answers = [];
  testBackupButtonText = "Sauvegarder le test";
  questionnaire: QuestionnaireData | null = null;
  saved = false;
  triedValidating = false;
  errormsg = "";
  backupServer = true;
  loadingStatus = "";
  showErrorsOnly = false;

  closeHelpModal() {
    this.showHelpModal = false;
    // this.$nextTick(() => {
    //   this.$bvModal.hide('helpmodal')
    // })
  }

  hack(choices, hastitleright){
    const arr = choices.map((e, i) => [e, i]);
    if(!hastitleright){
      return arr
    }else{
      const middle = +(choices.length/2).toFixed(0)
      const ans = arr.slice(0,middle).concat([[{}, -1]], arr.slice(middle, choices.length))
      return ans
    }
  }

  shouldShowQuestion(q, i){
    const qtitle = (q.title.content || "").toLowerCase();
    return (!this.showErrorsOnly || this.answerHasIssue(q)) &&
           this.isAdmin ||
           i > 0 ||
           !(
             (qtitle.includes('email du') && q.answer.content) ||
             (qtitle.includes('entreprise') && q.answer.content)
           )
  }

  handleHelpOK() {
    axios
    .post("/api/help", {
      id: this.qId,
      description: this.problemDescription
    })
    .then(resp => {
      console.log(resp);
      this.$toast.info(
        "Votre demande d'aide a été envoyée. Nous vous contacterons par email dans les plus brefs délais."
      );
      this.closeHelpModal();
    })
    .catch(err => {
      this.setHTTPErr(err);
      this.closeHelpModal();
    });
  }

  get qType() {
    return this.$route.path.split("/").slice(-1)[0];
  }

  saveTest(finished, hidden) {
    if (this.viewOnly && !this.isAdmin) {
      return;
    }
    if (!hidden) {
      this.testBackupButtonText = "Sauvegarde en cours...";
    }
    this.backupServer = false;
    if (finished) {
      this.triedValidating = true;
    }
    if (!finished || this.testIsReady) {
      if(finished){
        this.shouldBackup = false;
      }
      axios
      .post("/api/questionnaire", {
        questionnaire: this.questionnaire,
        id: this.qId,
        "q-type": this.qType,
        finished: finished
      })
      .then(ans => {
        console.log(ans);
        this.backupServer = true;
        if (!hidden) {
          this.testBackupButtonText = "Test sauvegardé";
        }

        if (finished) {
          this.saved = true;
          this.viewOnly = true;
          this.$router.push('/dashboard?test-send-success=ok')
        }
      })
      .catch(err => {
        if (!hidden) {
          this.setHTTPErr(err);
          this.backupServer = false;
          this.testBackupButtonText = "Sauvegarder le test";
        }
      });
    } else {
      this.errormsg = "Merci de répondre à toutes les questions";
      this.$toast.warning("Merci de répondre à toutes les questions");
      this.backupServer = false;
      this.testBackupButtonText = "Sauvegarder le test";
    }
  }

  get testIsReady() {
    return (
      this.questionnaire &&
      this.questionnaire.sections.every(s => {
        return !s.questions.some(this.answerHasIssue);
      })
    );
  }

  resetq(q) {
    if (this.isAdmin) {
      q.choice = -1;
    }
  }

  resetQuestionnaire(fill) {
    if (fill) {
      if (this.questionnaire) {
        this.triedValidating = false;

        this.questionnaire.sections.forEach(s => {
          if (
            s.questions.length > 0 &&
            (Object.prototype.hasOwnProperty.call(s, "choices") ||
             Object.prototype.hasOwnProperty.call(s.questions[0], "choices"))
          ) {
            s.questions = (s.questions as Array<RadioFieldChoice>).map(function(
              q,
              i
            ) {
              if (fill) {
                q.choice =
                  i %
                (
                  (s as QuestionnaireSectionRadioField).choices ||
                  (q as RadioQuestion).choices
                ).length;
              } else {
                q.choice = -1;
              }
              return q;
            });
          }
        });
      }
    } else {
      this.loadQuestionnaire()
      .then(() => {
        if (this.questionnaire) {
          this.triedValidating = false;
        }
      })
      .catch(console.log);
    }
  }

  answerHasIssue(q) {
    const facl = [
      "4 - Citez vos éventuels atypismes liés à votre profil : "
    ];
    if (~facl.indexOf(q.title.content)) {
      return false;
    } else {
      return (
        (q.choice != undefined && q.choice == -1) ||
        (q.answer != undefined &&
         (q.answer.content == undefined || q.answer.content.length == 0))
      );
    }
  }

  async loadQuestionnaire() {
    let rights = "view";

    try {
      this.qId = await this.fetchQuestionnaireId();
      console.log("**** get qId ****");
      console.log(this.qId);
    } catch (error) {
      this.$toast.error("Une erreur est survenue: " + error);
    }

    if (this.getUser.privileges == "enduser") {
      console.log("**** **** enduser **** ****");
      try {
        console.log("**** questionnaire rights ****");
        const rs = await axios.get("/api/get-questionnaire-rights", {
          params: { id: this.qId }
        });
        console.log(rs);
        console.log(rs.data);
        rights = rs.data;
      } catch (error) {
        if (this.$route.query.id) {
          this.$router.push({ query: {} });
          location.reload();
        }
        rights = "nothing";
      }
    } else {
      console.log(this.getUser);
      console.log(this.getUser.privileges);
      console.log("user is not an enduser");
    }

    this.viewOnly = rights == "view";

    this.loadingStatus = "loading";

    return axios
    .get("/api/questionnaire", {
      params: { "q-type": this.qType, id: this.qId }
    })
    .then(ans => {
      console.log("QUESTIONNAIRE");
      console.log(ans.data);
      const q = ans.data.data;
      this.referrer = ans.data.referrer;
      if (!(q.qtype.startsWith(this.qType) || this.qType.startsWith(q.qtype))) {
        console.log("inconsistent")
        console.log(q.qtype)
        console.log(this.qType)
        this.$toast.error(
          "une erreur est survenue: type de questionnaire inconsistent, " +
          q.qtype +
          " != " +
          this.qType
        );
      } else {
        this.questionnaire = q;
        this.backupServer = true;
        this.testBackupButtonText = "Test sauvegardé";
        this.loadingStatus = "ready";
      }
    })
    .catch(err => {
      this.loadingStatus = "error";
      this.setHTTPErr(err);
    });
  }

  @Watch("triedValidating")
  tvchanged(v) {
    if (v && this.showErrorsOnly) {
      this.showErrorsOnly = false;
    }
  }
  @Watch("showErrorsOnly")
  veochanged(v) {
    if (v) {
      this.errormsg = "";
      window.scrollTo(0, 0);
      this.$toast.info(
        'Vous êtes maintenant en mode "visualisation des erreurs", seulement les réponses manquantes sont affichées.'
      );
    }
  }

  @Watch("questionnaire", { deep: true })
  questionnaireChanged(v) {
    // console.log('questionnaire changed')
    if (v && !this.viewOnly) {
      // console.log('backing up localStorage')
      this.backupServer = false;
      this.testBackupButtonText = "Sauvegarder le test";
      // localStorage["questionnaire_" + this.qType] = JSON.stringify(v);
    }
  }

  @Watch("getUser.id")
  userIdChanged(id, oldid) {
    console.log("user id changed: " + oldid + " -> " + id);
    this.loadQuestionnaire();
  }

  async fetchQuestionnaireId() {
    const routeQid = this.$route.query.id;
    if (routeQid) {
      return routeQid;
    } else {
      try {
        const resp = await axios.get("/api/current-questionnaire-id", {
          params: { "q-type": this.qType }
        });
        this.$router.push({ query: { id: resp.data.id } });
        return resp.data.id;
      } catch (err) {
        this.setHTTPErr(err);
      }
    }
  }

  setHTTPErr(err) {
    console.log(err);
    console.log("$$$$$$$$ error $$$$$$$$");
    console.log(err);
    console.log(err.response.data);
    this.errormsg = err.response.data.message;
    this.$toast.error(err.response.data.message || "err");
  }

  async init() {
    console.log("initialization for " + this.getUser.id);
    if (localStorage["questionnaire_" + this.qType]) {
      delete localStorage["questionnaire_" + this.qType];
      delete localStorage["version_" + this.qType];
      delete localStorage["metadata_" + this.qType];
    }

    if (this.getUser.id) {
      await this.loadQuestionnaire();
    }

    setInterval(() => {
      if (!this.backupServer && !this.viewOnly && this.shouldBackup) {
        console.log('backing up...')
        this.saveTest(false, true);
      }
    }, 10000);
  }

  @Watch("saved")
  savedChanged(v) {
    if (v) {
      this.$toast.success(
        "Le questionnaire a été envoyé. Vous recevrez vos résultats dès que possible."
      );
    }
  }

  @Watch("viewOnly")
  viewOnlyChanged(v) {
    if (v && this.getUser.privileges == "enduser") {
      setTimeout(() => {
        this.$toast.info(
          'Vous êtes désormais en mode "lecture seule". Vous pouvez voir vos réponses, mais pas les modifier.',
          {timeout: 10000}
        );
      }, 2000);
    }
  }

  mounted() {
    this.isAdmin = this.$route.query.admin != undefined;
    this.init()
    .then(console.log)
    .catch(console.error);
  }
}
