




















































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { Record, Engine } from "@/types";
import QuestionnaireDownloader from "@/components/QuestionnaireDownloader.vue";
import { mapGetters } from "vuex";

axios.defaults.baseURL = process.env.BASE_URL;
import _ from "lodash";


interface DeSIACTIVRecord {
  available: boolean;
  creationDate: Date | null;
  modificationDate: Date | null;
}

@Component({
  components: { QuestionnaireDownloader },
  computed: mapGetters(["getUser"])
})
export default class EndUserQuestionnaireExplorer extends Vue {
  questionnairesAvailable: Array<Record> = [];
  questionnairesToFinish: Array<Record> = [];
  questionnairesFinished: Array<Record> = [];

  questionnaireActivToFinish: Array<DeSIACTIVRecord> = [];
  questionnaireActivFinished: Array<DeSIACTIVRecord> = [];
  
  baseurl = process.env.BASE_URL;
  getUser: any;
  
  get questionnaires() {
    return this.questionnairesAvailable.concat(
      this.questionnairesToFinish,
      this.questionnairesFinished
    );
  }

  get DeSIACTIVquestionnaires(){
    return this.questionnaireActivToFinish.concat(
      this.questionnaireActivFinished,
    );
  }

  selected: Record | null = null;
  // @Prop()
  // loggedin: boolean | undefined;
  @Prop()
  private engines: Array<Engine> | undefined;
  
  DeSI_premium_activitiesId: any = [];

  questionnaireNeuroscienceToFinish: any = [];
  questionnaireNeuroscienceFinished: any = [];

  get evaluationQuestionnaires(){
    return this.questionnaireNeuroscienceToFinish.concat(
      this.questionnaireNeuroscienceFinished
    );
  }


  disableACTIVq(a){

      var prem = false;
      if (a.qtype == "DeSI_ACTIV")
      {
        axios
        .get("/api/DeSI_premium_activitiesId", {params: {activitiesId: a.id}})
        .then( ans => {
            this.DeSI_premium_activitiesId = ans.data;            
        })         
         
        if (this.DeSI_premium_activitiesId.length > 0){
          prem = true;
        }
      } 
      return prem;
  }
  
  start(a){

    if (a.qtype == "DeSI_ACTIV"){
      // id : "63cabcac065aa2ee09c5600d"
      // job : undefined
      // qtype : "DeSI_ACTIV"
      // referrer : "amel.alorem.1@gmail.com"
      // started : false
      // status : "available"      
      axios.get('/api/create-DeSI-ACTIVquestionnaire', {params: {txId: a.id, txJob: a.Job}})
      .then(resp => {
        const id = resp.data.id;
        console.log('RESP from create DeSI-ACTIV questionnaire: ')
        console.log(resp.data)
        this.$router.push(this.baseurl + 'DeSI-ACTIV' + '?id=' + id)
      })
      .catch()      

    }

    else{
      axios.get('/api/create-questionnaire', {params: {txId: a.id}})
      .then(resp => {
        const id = resp.data.id;
        console.log('RESP from create questionnaire: ')
        console.log(resp.data)
        if ( a.qtype == 'S78'){
          this.$router.push(this.baseurl + 'S7+8' + '?id=' + id)
        }
        else{
          this.$router.push(this.baseurl + a.qtype + '?id=' + id)
        }
      })
      .catch()      
    }
  }
  
  fetchData() {
    axios
    .get("/api/questionnaires-metadata")
    .then(ans => {
      axios
      .get("/api/unspent-tx", {params: {userId: this.getUser.id}})
      .then(anstx => {
        console.log('UNSPENT TXS:')
        console.log(anstx.data)
        // 
        // { "creationDate": "2021-06-20T11:13:25.000Z", "engine": "2bdae24ffa5cd2852f006e390216623981eddf6b6bbe96f3a5e1a17c4017d604", "finished": false, "id": "60cf22d58f86a93a6760c506", "identity": { "company": "Néant", "dob": "hellodn", "forname": "hhh", "job": "1234", "mail": "mikaeldusenne@mac.com", "name": "hello" }, "qtype": "S7+8", "started": true, "userId": "mikaeldusenne@mac.com", "status": "toFinish", "meta": { "hovered": false, "isOpen": false, "reloadingTxt": false } }
        // const creds = Object.entries(this.getUser.credits.map(e =>)
        
        function cleanOrigin(e){
          if(e.userId){
            return e.userId;
          }else if(e._type == "JobBoardFormCredit"){
            return "Job-Board"
          }else{
            return e
          }
        }
        
        this.questionnairesAvailable = anstx.data.map(tx => {
          return {
            id: tx.id,
            job: tx.job,
            status: "available",
            qtype: tx.type,
            started: false,
            referrer: cleanOrigin(tx.origin),
            meta: {
              hovered: false,
              isOpen: false,
              reloadingTxt: false
            }
          }
        })
      }).catch(console.log)
      
      console.log("questionnaires:");
      console.log(ans.data);
        const prepare = function(e) {
          e.creationDate = new Date(e.creationDate);
          if (e.modificationDate) {
            e.modificationDate = new Date(e.modificationDate);
          }
          e.status =
            e.started == undefined || e.started == true
              ? e.finished
                ? "finished"
                : "toFinish"
              : "toFinish";
          e.meta = {
            hovered: false,
            isOpen: false,
            reloadingTxt: false
          };
          return e;
        };

        const f = b => {
          return _.sortBy(ans.data.filter(e => e.finished == b).map(prepare), [
            e => -e.creationDate.getTime()
          ]);
        };
        this.questionnairesFinished = f(true);
        this.questionnairesToFinish = f(false);

        console.log("the data is:");
        console.log(this.questionnaires);

      axios
      .get("/api/DeSIACTIVquestionnaires-metadata", {params: {userId: this.getUser.id}})
      .then(ans2 => {
        console.log(ans2.data)

          const prepare = function(e) {
            e.creationDate = new Date(e.creationDate);
            if (e.modificationDate) {
              e.modificationDate = new Date(e.modificationDate);
            }
            e.status =
              e.started == undefined || e.started == true
                ? e.finished
                  ? "finished"
                  : "toFinish"
                : "toFinish";
            e.meta = {
              hovered: false,
              isOpen: false,
              reloadingTxt: false
            };
            return e;
          };

          const f = b => {
            return _.sortBy(ans2.data.filter(e => (e.state != 'finished') == b).map(prepare), [
              e => -e.creationDate.getTime()
            ]);
          };

          this.questionnaireActivToFinish = f(true);

          this.questionnaireActivFinished = f(false);


      axios
      .get("/api/EvaluationNeuroscienceQuestionnaire-metadata")
      .then(ans3 => {

          const prepare = function(e) {
            e.creationDate = new Date(e.creationDate);
            if (e.modificationDate) {
              e.modificationDate = new Date(e.modificationDate);
            }
            e.status =
              e.started == undefined || e.started == true
                ? e.finished
                  ? "finished"
                  : "toFinish"
                : "toFinish";
            e.meta = {
              hovered: false,
              isOpen: false,
              reloadingTxt: false
            };
            return e;
          };

          const f2 = b => {
            return _.sortBy(ans3.data.filter(e => (e.state != 'finished') == b).map(prepare), [
              e => -e.creationDate.getTime()
            ]);
          };
 
          this.questionnaireNeuroscienceToFinish =  f2(true);
          this.questionnaireNeuroscienceFinished = f2(false);
 
        })



      }).catch(console.log)

      })  
    .catch(e => {
      console.log(e);
      this.$toast.error(e.data);
    });
  }

  clickAnswer() {
    // a.meta.isOpen = !a.meta.isOpen;
  }

  selectQ(a) {
    this.selected = a;
  }

  hoverAnswer(a, b) {
    a.meta.hovered = b;
  }

  private download(e) {
    window.open(this.baseurl + "download/?id=" + e.id, "_blank");
  }

  mounted() {
    this.fetchData();
  }
}
