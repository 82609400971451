import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import QuestionnaireS2 from "../views/QuestionnaireS2.vue";
import Scores from "../views/Scores.vue";
import UserActivation from "../views/UserActivation.vue";
import Admin from "../views/Admin.vue";
import PaiementTest from "../views/PaiementTest.vue";
import JobBoard from "../views/JobBoard.vue";
import DeSI_ACTIV from "../views/DeSI_ACTIV.vue";
import DeSI_ACTIV_Scores from "../views/DeSI_ACTIV_Scores.vue"
import NeuroscienceEvaluationQuestionnaire from "../components/NeuroscienceEvaluation/NeuroscienceEvaluationQuestionnaire.vue";
import NeuroscienceEvaluationScores from "../components/NeuroscienceEvaluation/NeuroscienceEvaluationScores.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/paiement-test",
    name: "PaiementTest",
    component: PaiementTest
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Admin
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/job-board",
    name: "JobBoard",
    component: JobBoard
  },
  {
    path: "/user/activate/*",
    name: "UserActivation",
    component: UserActivation
  },
  {
    path: "/scores",
    name: "Scores",
    component: Scores
  },
  {
    path: "/DeSI-ACTIV",
    name: "DeSI_ACTIV",
    component: DeSI_ACTIV
  },  
  {
    path: "/DeSI-ACTIVScores",
    name: "DeSI_ACTIV_Scores",
    component: DeSI_ACTIV_Scores   
  },
  {
    path: "/Evaluation_Neuroscience",
    name: "Evaluation_Neuroscience",
    component: NeuroscienceEvaluationQuestionnaire
  },
  {
    path: "/Evaluation-Neuroscience-Scores",
    name: "Evaluation-Neuroscience-Scores",
    component: NeuroscienceEvaluationScores
  },
  {
    path: "/*",
    name: "QuestionnaireS2",
    component: QuestionnaireS2
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
