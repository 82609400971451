










































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import { User, Privileges } from "@/types";
import CreditsManager from "@/components/CreditsManager.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

interface TxOriginUser {
  userId: string;
}

interface TxOriginPaid {
  id: string;
}

type TxOrigin = TxOriginUser | TxOriginPaid;

interface Transaction {
  amount: number;
  dest: string;
  id: string;
  origin: TxOrigin;
}

interface UserObj {
  data: User;
  backup: User;
  editing: boolean;
  isNew: boolean;
  hovered: boolean;
  transactions: Transaction[];
}

interface UserF {
  id: string | null;
  company: string | null;
  resume: boolean | null;
}

@Component({
  components: { CreditsManager },
  computed: mapGetters(["getUser"])
})
export default class JobBoardManagement extends Vue {
  @Prop({ default: "all" })
  userType!: string;

  @Prop({ default: "Gestion des utilisateurs" })
  title!: string;

  activationMailSendResult = "";
  getUser: any;
  
  
  credits = ["P2T_FREE", "P2T_OPT1", "P2T_OPT2", "P2T_OPT3"]
  creditsText = {
    P2T_FREE: "option gratuite",
    P2T_OPT1: "option 1",
    P2T_OPT2: "option 2",
    P2T_OPT3: "option 3",
  }

  
  userFilter: UserF = {
    id: null,
    company: null,
    resume: null
  };

  private users: Array<UserObj> = [];
  private selected: UserObj | null = null;
  selectU(a) {
    if(this.selected && this.selected.data == a){
      this.selected = null;
    }else{
      this.selected = {
        data: a,
        backup: a,
        editing: false,
        isNew: false,
        hovered: false,
        transactions: [],
      };
    }
  }

  get filteredUsers() {
    return this.users.filter(this.userFacetteOk);
  }

  userFacetteOk(u) {
    return (
      (this.userFilter.id == null || this.facetteIdRegeps.test(u.id)) &&
      (this.userFilter.company == null ||
       this.facetteCompanyRegeps.test(u.company)) &&
      (this.userFilter.resume == null ||
       this.userFilter.resume == u.resume)
    );
  }

  get facetteIdRegeps() {
    return !this.userFilter.id
         ? new RegExp("", "i")
         : new RegExp(this.userFilter.id, "i");
  }
  get facetteCompanyRegeps() {
    return !this.userFilter.company
         ? new RegExp("", "i")
         : new RegExp(this.userFilter.company, "i");
  }


  resetFilter() {
    this.userFilter = {
      id: null,
      company: null,
      resume: null
    };
  }


  getUsers() {
    axios
    .get("/api/jobboardusers", {params: {type: 'jobboard'}})
    .then(resp => {
      console.log('JOBBOARD USERS')
      console.log(resp.data);
      this.users = _.sortBy(
        resp.data
        .map(u => {
          u.created = new Date(u.created);
          u.modified = new Date(u.modified);
          return u;
        }),
        [e => -e.created.getTime()]
      );

      console.log(resp);
    })
    .catch(console.log);
  }


  @Watch("selected.data.id")
  selectedChanged(id) {
    if (this.selected && !this.selected.isNew) {
      axios
      .get("api/transactions", { params: { user: id } })
      .then(ans => {
        console.log("the transaction list is:");
        console.log(ans.data);
        const tx = ans.data.map(e => {
          console.log(e.txdate);
          try {
            e.txdate = new Date(e.txdate);
          } catch (err) {
            e.txdate = null;
          }
          return e;
        });
        console.log("TRANSACTIONS for", id);
        console.log((this.selected as any).transactions);
        console.log(this.selected);
        this.$set(this.selected as UserObj, "transactions", tx);
      })
      .catch(console.log);
    }
  }

  sendActivation(u) {
    axios
    .post("/api/activate-user", u.data)
    .then(resp => {
      console.log(resp);
      this.activationMailSendResult = "email envoyé!";
    })
    .catch(err => {
      console.log(err);
      this.activationMailSendResult = err.data;
    });
    }

  
  mounted() {
    this.getUsers();
  }
}
