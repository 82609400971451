


























import { Component, Vue, Prop } from "vue-property-decorator";
import PosteMatching from "@/components/scores/PosteMatching.vue";

interface Opt{
  text: string;
  value: string | null;
}

@Component({
  components: { PosteMatching },
})
export default class PosteMatchingReport extends Vue {
  @Prop({default: () => []})
  postes_details!: any[];
  
  get postes(){
    return this.postes_details?Object.keys(this.postes_details):[]
  }
  
  selectedPoste: string | null = null;
  
  
  get postes_opts(){
    const l : Opt[] = [{text: "Sélectionner un poste...", value: null}]
    return l.concat(this.postes.slice(4)
      .map(e => {return {text: e, value: e}}))
  }
}
