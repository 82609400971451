





















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

import axios from "axios";
axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {},
  computed: mapGetters(["getId"])
})
export default class Login extends Vue {
  loggedin: boolean | null = null;
  showReset = false;
  emailReset = "";
  infomsg = "";
  loginForm = {
    id: "",
    password: ""
  };

  resetPassword() {
    if (this.emailReset.length) {
      axios
        .get("/api/reset-password", { params: { id: this.emailReset } })
        .then(ans => {
          console.log(ans);
          this.infomsg =
            "Veuillez consulter votre boîte mail pour réinitialiser votre mot de passe";
        })
        .catch(console.log);
    }
  }

  tryLogin() {
    return axios
      .post("/login", this.loginForm)
      .then(resp => {
        this.loggedin = true;
        console.log((resp as any).id);
        const u = resp.data;

        axios
          .get("/api/balance", { params: { userId: u.id } })
          .then(respBal => {
            u.credits = respBal.data;

            this.$store
              .dispatch("setUser", u)
              .then(console.log)
              .catch(error => {
                console.error(error);
              });
          })
          .catch(console.log);
      })
      .catch(e => {
        this.$emit("err", e.response.data);
        this.loggedin = false;
      });
  }

  login() {
    this.$emit("err", "");
    this.tryLogin()
      .then(() => {
        // (this.$refs.mybtn as HTMLElement).click();
        // window.location.reload();
      })
      .catch(console.log);
  }

  @Watch("loginForm.id")
  @Watch("loginForm.password")
  changed() {
    this.$emit("err", "");
  }
}
