

































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { Record } from "@/types";
import QuestionnaireDownloader from "@/components/QuestionnaireDownloader.vue";
import _ from "lodash";
import { mapGetters } from "vuex";

axios.defaults.baseURL = process.env.BASE_URL;


@Component({
  components: { QuestionnaireDownloader },
  computed: mapGetters(["getUser"])
})

export default class QuestionnaireExplorer extends Vue {
  @Prop({ default: false })
  displayHelp!: boolean;
  baseurl = process.env.BASE_URL;

  selected: Record | null = null;
  getUser: any;

  allQuestionnaires: any[] = [];

  clickAnswer() {
    // a.meta.isOpen = !a.meta.isOpen;
  }

  selectQ(a) {
    this.selected = a;
  }

  hoverAnswer(a, b) {
    a.meta.hovered = b;
  }

  private download(e) {
    window.open(this.baseurl + "download/?id=" + e.id, "_blank");
  }

  getAllQuestionnaires(){

    axios
    .get("/api/questionnaires-metadata")
    .then(ans => {
          console.log(ans.data);

        this.allQuestionnaires = ans.data.map(e => {
            e.creationDate = new Date(e.creationDate);
            if (e.modificationDate) {
              e.modificationDate = new Date(e.modificationDate);
            }
            e.meta = {
              hovered: false,
              isOpen: false,
              reloadingTxt: false
            };
            return e;
          });

        axios
        .get("/api/EvaluationNeuroscienceQuestionnaire-metadata")
        .then(ans => {
          
          console.log(ans.data);

          this.allQuestionnaires = _.concat(
            this.allQuestionnaires,
            ans.data.map(e => {
              e.creationDate = new Date(e.creationDate);
              if (e.modificationDate) {
                e.modificationDate = new Date(e.modificationDate);
              }
              e.meta = {
                hovered: false,
                isOpen: false,
                reloadingTxt: false
              };
              e.qtype = "Evaluation Neurosciences";
              e.identity = {'forname': e.userId, 'name': ''};

              return e;
            })
          );
        });
        
        axios
        .get("/api/DeSIACTIVquestionnaires-metadata")
        .then(ans => {

          let DeSIACTIVquestionnaires = ans.data.map(e => {
              e.creationDate = new Date(e.creationDate);
              if (e.modificationDate) {
                e.modificationDate = new Date(e.modificationDate);
              }
              e.meta = {
                hovered: false,
                isOpen: false,
                reloadingTxt: false
              };
              e.qtype = "DeSI-Activités";
              e.identity = {'forname': e.userId, 'name': ''};
              return e;
            });

          axios.get("/api/DeSI_premium")
          .then(ans => {
            let new_tab: any[] = [];
            DeSIACTIVquestionnaires.forEach(element1 => { 
                  var exists = false;
                  ans.data.forEach(element2 => {
                      if (element1['id'] == element2.activitiesId){
                        exists = true;
                      }
                    }
                  )
                  if (exists == false){
                    new_tab.push(element1);
                  }
              });

            this.allQuestionnaires = _.sortBy(_.concat(this.allQuestionnaires, new_tab), [e => -e.creationDate.getTime()]);
          })


      })
    })
    .catch(e => {
        this.$toast.error(e.response.data);
    })
  }

  mounted() {
    this.getAllQuestionnaires();
  }
}
