

















































import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
import Paiement from "@/components/Paiement.vue";
import { Order } from "@/types";

declare global {
  interface Window {
    KR: any;
  }
}

interface Price {
  name: string;
  value: number;
}

@Component({
  components: { Paiement },
  computed: mapGetters(["getUser"])
})
export default class PaiementTest extends Vue {
  @Prop()
  mode : string | undefined;
  
  @Prop({default: () => []})
  credits: string[] | undefined
  
  showForm = false;
  errormsg = "";
  infomsg = "";
  amount = 8.88;
  getUser: any;
  creditsText = {
    P2T_FREE: "option gratuite",
    P2T_OPT1: "option 1",
    P2T_OPT2: "option 2",
    P2T_OPT3: "option 3",
  }


  depouillements: Array<Price> = [];
  selectedDepouillement = "";
  order: Order | null = null;
  
  depouillementTitle(e){
    return this.creditsText[e.name] + " (" + e.value + "€ )";
  }
  
  get depouillementOptions() {
    return [{ text: "Sélectionner un dépouillement", value: "" }].concat(
      this.depouillements.filter(e => ~(this.credits||[]).indexOf(e.name)).map(e => {
        const txt = this.depouillementTitle(e);
        return {
          text: txt,
          value: e.name
        };
      })
    );
  }

  get orderDetails() {
    return {
      type: this.selectedDepouillement,
      description: this.selectedDepouillement
    };
  }
  onPaiementSuccess() {
    console.log('PAIEMENTFORM SUCCESS')
    this.$emit('paiementSuccess', this.selectedDepouillement)
  }

  onPaiementError() {
    this.$toast.error("Le paiement n'a pas pu aboutir, veuillez recommencer.");
    this.$emit('paiementError')
  }

  valider(e) {
    this.selectedDepouillement = e;
    axios
    .get("/api/create-paiement", {
      params: { type: e }
    })
    .then(resp => {
      console.log("paiement created");
      console.log(resp.data);
      this.order = {
        orderId: resp.data.id,
        amount: resp.data.amount,
        customer: {
          email: this.getUser.id
        },
        currency: resp.data.currency
      };
    })
    .catch(console.log);
  }

  mounted() {
    axios
    .get("/api/prices")
    .then(resp => {
      this.depouillements = Object.entries(resp.data)
      .filter(e => e[1])
      .map(e => {
        const val = e[1] as number;
        return {
          name: e[0],
          value: Number((val / 100).toFixed(2))
        };
      });
    })
    .catch(console.log);
  }
}
