








































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { mapGetters } from "vuex";
import { User } from "@/types";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  computed: mapGetters(["getUser"])
})
export default class Home extends Vue {
  message = "Welcome Home";
  getUser!: User;
  baseurl = process.env.BASE_URL;

  questionnaireTypeList: Array<string> = [];
  pwdRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
  pwdErrorMsg = "";
  pwd2ErrorMsg = "";
  errorMsg = "";
  id = "";
  password = "";
  password2 = "";

  get pwdOk() {
    return this.pwdRegex.test(this.password);
  }

  get pwd2Ok() {
    return this.password.length == 0 || this.password == this.password2;
  }

  get isOk() {
    return this.password.length > 0 && this.pwdOk && this.pwd2Ok;
  }

  get link() {
    return this.$route.path.split("/").slice(-1)[0];
  }

  submit() {
    console.log("submit");
    axios
      .post("/user/activate/" + this.link, { password: this.password })
      .then(ans => {
        console.log(ans);
        window.location.replace(this.baseurl + "dashboard?activate=success");
      })
      .catch(err => {
        console.log(err);
        this.errorMsg = err.data;
      });
  }

  @Watch("password")
  pwdChanged() {
    this.pwdErrorMsg = this.pwdOk
      ? ""
      : "Le mot de passe doit contenir au moins 8 lettres ou chiffres";
  }

  @Watch("password2")
  pwd2Changed() {
    this.pwd2ErrorMsg = this.pwd2Ok
      ? ""
      : "Les mots de passe ne correspondent pas!";
  }

  mounted() {
    if (this.getUser) {
      axios
        .get("/logout")
        .then(() => {
          window.location.reload(false);
        })
        .catch(console.log);
    }

    axios
      .get("/api/id-from-activation-link", { params: { link: this.link } })
      .then(ans => {
        this.id = ans.data;
      })
      .catch(() => {
        this.errorMsg = "Lien d'activation non valide !";
      });
  }
}
