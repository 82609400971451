












































import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  computed: mapGetters(["getUser"])
})
export default class NavBar extends Vue {
  baseurl = process.env.BASE_URL;

  logout() {
    axios
      .get("/logout")
      .then(() => {
        window.location.replace("/");
      })
      .catch(console.log);
  }
}
