var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticStyle:{"display":"flex","align-items":"space-around"}},[(_vm.getUser.privileges=='admin' || _vm.getUser.privileges=='client')?_c('b-col',[_c('a',{staticClass:"btn btn-outline-primary",attrs:{"href":_vm.baseurl + 'DeSI-ACTIV?id=' + _vm.DeSIACTIVqId,"target":"_blank"}},[_vm._v(" Voir réponses DeSI-Métiers ")])]):_vm._e(),(_vm.getUser.privileges=='admin' || _vm.getUser.privileges=='client')?_c('b-col',[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":function($event){return _vm.printDeSIActivityReport()}}},[_c('strong',[_vm._v("Rapport DeSI-Métiers")])])]):_vm._e()],1),(_vm.$route.query.admin=='1' )?_c('b-row',[_c('pre',[_vm._v(_vm._s(JSON.stringify(_vm.referrer, null, 2)))])]):_vm._e(),_c('p',[_c('i',[_c('b',[_vm._v("Lecture :")]),_vm._v(" ces tests de préférences sont caricaturaux, donc la valeur la plus grande fait toujours 100 %")])]),_c('h4',[_vm._v("LES SECTEURS D'ACTIVITE LES PLUS EPANOUISSANTS ")]),_c('b-row',{attrs:{"align-h":"around"}},[_c('Plotly',{attrs:{"data":[{
                type: 'scatterpolar',
                r: _vm.secteurs_R,
                theta: _vm.secteurs_Theta,
                line: {color: 'rgb(255, 165, 0)'},
            }],"layout":{
                title: 'Secteurs d\'activité',
                polar: {
                    radialaxis: {
                    visible: true,
                    range: [0, 100]
                    }
                },
                height: 600,
                width: 800,
                showlegend: false,
                grid: {rows: 1, columns: 1}
            }}}),_c('div',{staticStyle:{"max-height":"35rem","overflow-y":"scroll"}},[_c('table',{staticClass:"table table-hover table-sm table-bordered table-sticky"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Secteur")]),_c('th',[_vm._v("Valeur")])])]),_c('tbody',_vm._l((_vm.sortedSecteurs),function(sect,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(sect.secteur)+" ")]),_c('td',[_vm._v(" "+_vm._s((sect.percent * 100).toFixed(2) + " %")+" ")])])}),0)])])],1),_c('h4',[_vm._v("VOS ACTIVITES LES PLUS EPANOUISSANTES ")]),_c('b-row',{attrs:{"align-h":"around"}},[_c('Plotly',{attrs:{"data":[{
                type: 'scatterpolar',
                r: _vm.activities_R,
                theta: _vm.activities_Theta,
                line: {color: 'rgb(255, 165, 0)'},
            }],"layout":{
                title: 'Types d\'activité',
                polar: {
                    radialaxis: {
                    visible: true,
                    range: [0, 100]
                    }
                },
                height: 450,
                width: 600,
                showlegend: false,
                grid: {rows: 1, columns: 1}
            }}}),_c('div',{staticStyle:{"max-height":"25rem","overflow-y":"scroll"}},[_c('table',{staticClass:"table table-hover table-sm table-bordered table-sticky"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Activité")]),_c('th',[_vm._v("Valeur")])])]),_c('tbody',_vm._l((_vm.sortedActivities),function(act,i){return _c('tr',{key:i},[(act.activity == 'Manager, motiver, animer')?_c('td',[_vm._v(" "+_vm._s(act.activity + " *")+" ")]):_c('td',[_vm._v(" "+_vm._s(act.activity)+" ")]),_c('td',[_vm._v(" "+_vm._s((act.percent * 100).toFixed(2) + " %")+" ")])])}),0)])]),_c('p',[_c('b',[_c('i',[_vm._v("*ATTENTION :")])]),_vm._v(" aimer Manager ne veut pas dire que l'on sait le faire. Ici nous détectons ce que la personne aime faire, à envie de faire. Pour savoir si elle a les aptitudes à Manager, se reporter au « Rapport Candidat ». Avoir les aptitudes se mesure / s'évalue donc autrement.")])],1),_c('h4',[_vm._v("Vision de la vie - Exigences / Desirs")]),_c('b-row',{attrs:{"align-h":"around"}},[_c('Plotly',{attrs:{"data":[{
                values: [_vm.scores.mental_posture.negative, _vm.scores.mental_posture.positive],
                labels: ['Sélective', 'Positive'],
                marker: {
                colors: [
                    'rgb(125, 125, 125)',
                    'rgb(255, 255, 0)']},
                domain: {column: 0},
                hoverinfo: 'label+percent',
                hole: .6,
                type: 'pie'
            }],"layout":{
                title: 'Vision de la vie',
                annotations: [
                    {
                    font: {
                        size: 20
                    },
                    showarrow: false,
                    text: '',
                    x: 0.17,
                    y: 0.5
                    } ],
                height: 400,
                width: 600,
                showlegend: true,
                grid: {rows: 1, columns: 1}
            }}}),_c('div',[_c('br'),_c('br'),_c('br'),_c('span',[_c('strong',[_vm._v(_vm._s("Perception de la vie :"))]),(_vm.scores.requirement_desire.life_vision > 0)?_c('p',[_vm._v(_vm._s("POSITIVE, grande ouverture à la Vie"))]):_c('p',[_vm._v(_vm._s("Exigences, je sais ce que je ne veux pas"))])]),_c('table',{staticClass:"table table-hover table-sm table-bordered table-sticky"},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" "+_vm._s("Vision positive")+" ")]),_c('td',[_vm._v(" "+_vm._s((_vm.scores.mental_posture.positive / (_vm.scores.mental_posture.positive + _vm.scores.mental_posture.negative)* 100).toFixed(2) + " %")+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s("Vision sélective")+" ")]),_c('td',[_vm._v(" "+_vm._s((_vm.scores.mental_posture.negative / (_vm.scores.mental_posture.positive + _vm.scores.mental_posture.negative)* 100).toFixed(2) + " %")+" ")])])])])])],1),_c('h5',[_c('u',[_vm._v("Ce que vous adoreriez faire :")])]),_c('div',{staticStyle:{"max-height":"20rem","overflow-y":"scroll"}},[_c('table',{staticClass:"table table-hover table-sm table-bordered table-sticky"},[_c('tbody',_vm._l((_vm.scores.bestRadioR),function(radioQ,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(radioQ)+" ")])])}),0)])]),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }