

































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import FileUploader from "@/components/FileUploader.vue";
import { UploadInfo } from "@/types";

axios.defaults.baseURL = process.env.BASE_URL;
@Component({
  components: { FileUploader }
})

export default class EngineExplorer extends Vue {
    
    private filepath: File | null = null;
    private maxSize: number = 50e6;
    private uploadButtonText: string = "Lancer la vérification";
    fileTooLarge = false;
    private uploadOnFocusOut: boolean = false;
    private loading = false;
    private uploaded = false;
    private uploadPercentage = 0;
    private uploadInfo: UploadInfo = {
        started: new Date(),
        transferred: 0,
        toTransfer: 0
    };

    SSnumber: number = 0;

    private checkData: any = {};

    get uploadpercent() {
        if (!(this.uploadInfo.transferred && this.uploadInfo.toTransfer)) {
        return "0";
        } else {
        return (
            ((100 * this.uploadInfo.transferred) / this.uploadInfo.toTransfer)
            .toFixed(0)
            .toString() + "%"
        );
        }
    }

    get formData() {
        if (this.filepath) {
            const f = new FormData();
            f.append(this.filepath.name, this.filepath);
            return f;
        } else {
            return null;
        }
    }

    get formDataSize() {
        return this.formData ? ([...this.formData.entries()][0][1] as any).size : 0;
    }

    private check(){
        this.uploadInfo = {
            started: new Date(),
            transferred: NaN,
            toTransfer: NaN
        };


        const updateUploadPercentage = evt => {
            this.uploadInfo.transferred = evt.loaded;
            this.uploadInfo.toTransfer = evt.total;
            this.$emit("progress", this.uploadInfo);
        };


        if (this.filepath) {
            this.loading = true;
            this.$emit("uploadStarted");

            const formData = this.formData;

            axios
            .post("/api/engineChecker", formData, {
                onUploadProgress: updateUploadPercentage
            })
            .then(ans => {
                this.checkData = ans.data;
                this.$emit("uploadFinished", ans.data);
                this.$emit("input", ans.data);
                this.loading = false;
                this.uploaded = true;
                this.$bvModal.show("modal-checker");
            })
            .catch(err => {
                console.log(err);
                this.$emit("uploadError", err.response.data);
                this.$toast.error(err.response.data);
                this.loading = false;
            });

        }
    
    }
    
    setFilePath(event) {
        this.filepath = event.target.files[0];
    }

    @Watch("filepath")
    filepathChanged() {
        if (this.formData && this.maxSize) {
            const sz = this.formDataSize;

            if (~this.maxSize && sz > this.maxSize) {
                this.fileTooLarge = true;
            } else {
                this.fileTooLarge = false;
                if (this.uploadOnFocusOut) {
                //this.check();
                }
            }
        }
    }
    

}
