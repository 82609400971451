




























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import PaginatedViewer from "@/components/PaginatedViewer.vue";
import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {
    PaginatedViewer
  }
})
export default class LocationExplorer extends Vue {
  countries: any[] = [];
  vals: any = {};
  divisions: any[] = [];
  country = {
    code_pays: "FR",
    nom_pays: "France",
    divisions: ["regions", "departements", "postals", "communes"]
  };
  region = "";

  @Prop({ default: () => [] })
  value!: any[];

  mobilites: any[] = [];

  dictsEquals(a, b) {
    let equal = true;
    Object.keys(a).forEach(k => {
      if (a[k] != b[k]) equal = false;
    });
    Object.keys(b).forEach(k => {
      if (a[k] != b[k]) equal = false;
    });
    return equal;
  }

  get location() {
    let divs = JSON.parse(JSON.stringify(this.divisions.filter(e => e)));
    divs = divs
      .filter(e => e)
      .map((e, i) => {
        Object.keys(e).forEach(k => {
          if (this.country.divisions) {
            if (
              !k.includes(
                this.country.divisions[i].substring(
                  0,
                  this.country.divisions[i].length - 1
                )
              )
            ) {
              delete e[k];
            }
          }
        });
        return e;
      });
    const d = JSON.parse(
      JSON.stringify([this.country].concat(divs.filter(e => e)))
    );
    if (d[0]["divisions"]) {
      delete d[0]["divisions"];
    }
    return d;
  }

  get countriesOpts() {
    return _.sortBy(this.countries, [e => e.nom_pays]).map(e => {
      return {
        text: e.nom_pays + " (" + e.code_pays + ")",
        value: e
      };
    });
  }

  get options() {
    const names = {
      pays: "un pays",
      regions: "une région",
      cantons: "un canton",
      communes: "une commune",
      postals: "un code postal",
      departements: "un département",
      provinces: "une province"
    };
    // console.log('compute opts')
    // console.log(this.vals)
    return this.country.divisions.map(e => {
      // console.log(e)
      if (!this.vals[e]) {
        return [];
      } else {
        console.log("SORTED divisions");
        console.log(this.vals[e]);
        console.log(this.vals[e].map(e => this.get_prop(e, "nom")));
        // console.log(_.sortBy(this.vals[e], [ee => this.mobilityGetProps(ee, 'nom')]))
        // console.log("----")
        return [
          { text: "-- Sélectionner " + names[e] + " --", value: null }
        ].concat(
          _.sortBy(this.vals[e], [ee => _.deburr((this.get_prop(ee, "nom") || "").toLocaleLowerCase())]).map(ee => {
            return {
              text: ee[Object.keys(ee).filter(eee => eee.includes("nom"))[0]],
              value: ee
            };
          })
        );
      }
    });
  }


  division_dict(l) {
    return l.reduce((acc, e) => {
      acc[e.code_name] = e.value;
      return acc;
    }, {});
  }

  get divisions_list() {
    // console.log('DIVISIONS:')
    // console.log(this.divisions)
    const l = [{ code_pays: this.country.code_pays }].concat(
      this.divisions.filter(e => e)
    );
    const ll: any[] = [];
    // console.log('DIVISION LIST')
    // console.log(l)
    l.filter(e => e).forEach(e => {
      const k = Object.keys(e).filter(
        ee =>
          ee &&
          ee.includes("code") &&
          !~ll.map(eee => eee.code_name).indexOf(ee)
      )[0];
      ll.push({
        code_name: k,
        value: e[k]
      });
    });
    return ll;
  }

  @Watch("country")
  countrychgd() {
    axios
      .get("/api/location", {
        params: this.division_dict(this.divisions_list.slice(0, 1))
      })
      .then(resp => {
        if (this.country.divisions) {
          this.$set(this.vals, this.country.divisions[0], resp.data.data);
          this.country.divisions.map((ee, ii) => {
            if (ii > 0) {
              delete this.vals[ee];
              this.divisions[ii] = null;
            }
          });
        }
        this.divisions[0] = null;
        Array(this.divisions.length)
          .fill(0)
          .map((_, i) => {
            if (i > 0) {
              delete this.divisions[i];
            }
          });
      })
      .catch(console.log);
  }

  @Watch("mobilites", { deep: true })
  locationchgd(v) {
    // console.log('Location explorer emits mobilites:')
    // console.log(v)
    this.$emit("input", v);
  }

  locIncludes(a, b) {
    const resp = Object.keys(a).reduce((acc, k) => {
      return acc && a[k] == b[k];
    }, true);
    return resp;
  }

  mobilityToDict(e, code) {
    const resp = e.reduce((acc, ee) => {
      Object.keys(ee)
        .filter(k => k.includes(code ? "code" : "nom"))
        .forEach(k => {
          acc[k] = ee[k];
        });
      return acc;
    }, {});
    return resp;
  }

  mobilitiesIncludes(a, b) {
    return this.locIncludes(
      this.mobilityToDict(a, false),
      this.mobilityToDict(b, false)
    );
  }

  mobilitiesEquals(a, b) {
    return this.dictsEquals(
      this.mobilityToDict(a, false),
      this.mobilityToDict(b, false)
    );
  }

  get newMobiliteIsInvalid() {
    return (
      !this.location ||
      this.location == [] ||
      this.mobilites.some(e => this.mobilitiesIncludes(e, this.location))
    );
  }

  removeMobility(e) {
    const i = this.mobilites.findIndex(ee => {
      return this.mobilitiesEquals(ee, e);
    });
    if (~i) {
      this.mobilites.splice(i, 1);
    }
  }

  addMobility(quiet) {
    if (!this.location || this.location == []) return;

    if (this.newMobiliteIsInvalid) {
      if (!quiet) this.$toast.error("cette mobilité est déjà dans la liste!");
    } else {
      this.mobilites.push(this.location);
      this.divisions = [];
    }
  }

  change(e, i) {
    axios
      .get("/api/location", {
        params: this.division_dict(this.divisions_list.slice(0, i + 1 + 1))
      })
      .then(resp => {
        this.$set(this.vals, this.country.divisions[i + 1], resp.data.data);
        this.country.divisions.map((ee, ii) => {
          if (ii > i + 1) {
            delete this.vals[ee];
            delete this.divisions[ii];
          }
        });
        this.divisions = this.divisions.slice(0, i + 1);
        this.divisions[i + 1] = null;
      })
      .catch(console.log);
  }

  get_prop(e, which) {
    const k = Object.keys(e).filter(k => k.includes(which))[0];
    return e[k];
  }

  mobilityGetProps(e, which) {
    return e.map(ee => this.get_prop(ee, which));
  }

  @Watch("value", { deep: true })
  valchgd(v) {
    if (v && v.length) {
      this.mobilites = v;
    }
  }

  mounted() {
    if (this.value && this.value.length) this.mobilites = this.value;
    if (this.country) {
      this.countrychgd();
    }
    axios
      .get("/api/location")
      .then(resp => {
        this.countries = resp.data;
      })
      .catch(console.log);
  }
}
