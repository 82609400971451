








































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

@Component
export default class PaginatedViewer extends Vue {
  @Prop()
  private endpoint!: string;

  @Prop({
    default: () => {
      return {};
    }
  })
  private params!: any;

  @Prop({ default: "" })
  private field!: string;

  @Prop({ default: () => [] })
  private itemFields!: string[];

  @Prop({ default: () => [] })
  private sort!: string[];

  @Prop({ default: 25 })
  private itemsPerPage!: number;

  @Prop({ default: false })
  private editable!: boolean;

  @Prop({ default: false })
  private selectable!: boolean;

  @Prop({ default: false })
  private customizable!: boolean;

  @Prop({ default: -1 })
  private selectableMaxN!: number;

  @Prop({ default: true })
  private searchable!: boolean;

  @Prop({ default: null })
  private keyField!: string;

  @Prop({ default: () => [] })
  private value!: any[];

  private page = 0;
  private npages = 0;

  viewOnlySelected = false;
  items: Array<any> = [];
  nitems = 0;
  editing = -1;
  editingField = null;
  search = "";
  selectedItems: any[] = [];
  customItems: any[] = [];
  removedItems: any[] = [];

  newElement: any = null;

  cut(s) {
    return (s || "").length > 50 ? s.substring(0, 50) + "..." : s;
  }

  createElement() {
    const d = {};
    this.itemFields.forEach(f => {
      d[f] = "";
    });
    this.newElement = d;
  }

  saveNewElement() {
    if (this.newElement && this.editable) {
      return axios
        .post(this.endpoint, {
          ...{
            fields: this.itemFields,
            data: this.newElement,
            new: true
          },
          ...this.params
        })
        .then(() => {
          this.newElement = null;
          this.$toast.success("le nouvel élément a été ajouté!");
          this.fetchData();
        })
        .catch(console.log);
    } else {
      // this.toggleSelectedItem(this.newElement)
      this.customItems.push(this.newElement);
      this.newElement = null;
      this.emitInput();
    }
  }

  resetSearch() {
    if (this.search) {
      this.search = "";
      this.searchf();
    }
  }

  searchf() {
    this.page = 0;
    this.fetchData();
  }

  findIndexSameStuff(ref, e) {
    return ref.findIndex(ee => {
      return Object.keys(ee).every(k => {
        return e && ee[k] == e[k];
      });
    });
  }

  findIndexSelected(e) {
    return this.findIndexSameStuff(this.selectedItems, e);
  }

  get sortArray() {
    return (this.sort.length ? this.sort : this.itemFields).map(k => {
      return e => {
        return _.deburr((e[k] || "").toLocaleLowerCase());
      };
    });
  }

  mixArrays(l) {
    return _.sortBy(l, this.sortArray);
  }
  allItems : any[] = []
  // get allItems() {
  //   return this.mixArrays(
  //     this.items.concat(this.customItems, this.removedItems)
  //   );
  // }

  get allSelectedItems() {
    return this.mixArrays(this.selectedItems.concat(this.customItems));
  }

  fetchData() {
    this.editing = -1;
    this.editingField = null;
    return axios
      .get(this.endpoint, {
        params: {
          ...{
            fields: this.itemFields,
            page: this.page,
            perpage: this.itemsPerPage,
            search: this.search
          },
          ...this.params
        }
      })
      .then(ans => {
        // console.log('response for ' + this.endpoint + JSON.stringify(this.params))
        // console.log(ans)
        this.items = this.field ? ans.data.data[this.field] : ans.data.data;

        this.npages = ans.data.npages;
        this.nitems = ans.data.total;
      })
      .catch(console.log);
  }

  goto(k) {
    this.page = k;
  }

  save() {
    const item = this.items.find(e => e._id == this.editing)
    console.log("editing: " + JSON.stringify(item))
    if (this.editable && ~this.editing) {
      axios
        .post(this.endpoint, {
          ...{
            fields: this.itemFields,
            data: item,
            search: this.search
          },
          ...this.params
        })
        .then(() => {
          this.editing = -1;
        })
        .catch(console.log);
    }
  }

  edit(item, field) {
    this.$emit('click', item)
    if (this.editable) {
      this.editing = item._id;
      this.editingField = field;
    } else if (this.selectable) {
      this.select(item);
    }
  }

  @Watch("page")
  pgchgd() {
    this.fetchData();
  }

  @Watch("items", {deep: true})
  itemschgd(v) {
    if(!~this.editing){
      this.allItems = this.mixArrays(
        v.concat(this.customItems, this.removedItems)
      );
    }
  }

  @Watch("customItems", {deep: true})
  customitemschgd(v) {
    if(!~this.editing){
      this.allItems = this.mixArrays(
        this.items.concat(v, this.removedItems)
      );
    }
  }

  @Watch("removedItems", {deep: true})
  removeditemschgd(v) {
    if(!~this.editing){
      this.allItems = this.mixArrays(
        this.items.concat(this.customItems, v)
      );
    }
  }

  @Watch("editing", {deep: true})
  editingchgd(v) {
    if(!~v){
      this.allItems = this.mixArrays(
        this.items.concat(this.customItems, this.removedItems)
      );
    }
  }

  setSelectedItems(l) {
    const selected: Array<any> = [],
    custom: any[] = [];
    l.forEach(e => {
      if (!e._id) {
        custom.push(e);
      } else {
        selected.push(e);
      }
    });
    this.customItems = custom;
    this.selectedItems = selected;
  }

  emitInput() {
    this.$emit("input", this.allSelectedItems);
  }

  @Watch("value", { deep: true })
  valuechgd(v) {
    this.setSelectedItems(v);
  }

  toggleSelectedItem(item) {
    if (item._id) {
      const index = this.findIndexSelected(item);
      // console.log("searching " + JSON.stringify(item) + " in " + JSON.stringify(this.selectedItems) + " -> " + index)
      if (index == -1) {
        if (
          ~this.selectableMaxN &&
          this.selectedItems.length + this.customItems.length >=
            this.selectableMaxN
        ) {
          this.$toast.error(
            "vous ne pouvez pas sélectionner plus de " +
              this.selectableMaxN +
              " élements. vouz avez déjà sélectionnéss :\n" +
              this.selectedItems
                .map(e => Object.values(e).join(" - "))
                .join("\n")
          );
        } else {
          this.selectedItems.push(item);
        }
      } else {
        this.selectedItems.splice(index, 1)[0];
      }
    } else {
      const removedIndex = this.findIndexSameStuff(this.removedItems, item);
      if (~removedIndex) {
        this.removedItems.splice(removedIndex, 1);
        this.customItems.push(item);
      } else {
        const customIndex = this.findIndexSameStuff(this.customItems, item);
        this.customItems.splice(customIndex, 1);
        this.removedItems.push(item);
      }
    }
    this.emitInput();
  }

  select(item) {
    this.toggleSelectedItem(item);
    // (this.viewOnlySelected ? this.selectedItems : this.allItems)[i]
    // );
  }

  mounted() {
    // this.page = +this.$route.query.jobpage || 0;
    if (this.value && this.value.length) {
      this.setSelectedItems(this.value);
    }
    this.fetchData();
  }
}
