import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import {faSearch, faSearchLocation, faEllipsisH} from "@fortawesome/free-solid-svg-icons";

import JsonViewer from "vue-json-viewer";

import VueLodash from "vue-lodash";
import lodash from "lodash";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueHead from "vue-head";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueLodash, { lodash: lodash });

// // import Vuelidate from "vuelidate";
// import * as VeeValidate from "vee-validate";

Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.use(JsonViewer);
// Vue.use(VueTelInput)

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
};

Vue.use(Toast, options);

library.add(faEdit);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faMinusSquare);
library.add(faPlusSquare);
library.add(faDownload);
library.add(faExclamationCircle);
library.add(faCaretRight);
library.add(faCaretDown);
library.add(faSync);

library.add(faWrench);

library.add(faSearch);
library.add(faSearchLocation);
library.add(faEllipsisH);


Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);
// Vue.use(Vuelidate);
// {inject: false}
// Vue.use(VeeValidate, {inject: false});
Vue.use(VueHead);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
