import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: null,
      credits: {},
      admin: false
    },
    loggedIn: null,
    jobSelected : "",
    metierList: [],
    DeSIActivQuestionnaireResponded : false,  //desi-activite combined to desi-talents to form a desi premium orientation
  },
  mutations: {
    changeUser(state, user) {
      state.user = user;
    },
    changeLoggedIn(state, b) {
      state.loggedIn = b;
    },
    changejobSelected(state, jobSelected){
      state.jobSelected = jobSelected;
    },
    changemetierList(state, metierList){
      state.metierList = metierList;
    },
    changeDeSIActivQuestionnaireResponded(state, questionnaireResponded){
      state.DeSIActivQuestionnaireResponded = questionnaireResponded;
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit("changeUser", user);
    },
    setLoggedIn({ commit }, b) {
      commit("changeLoggedIn", b);
    },
    setSelectedJob({ commit }, jobSelected) {
      commit("changejobSelected", jobSelected);
    },
    setmetierList({ commit }, metierList) {
      commit("changemetierList", metierList);
    },
    setDeSIActivQuestionnaireResponded({ commit }, questionnaireResponded) {
      commit("changeDeSIActivQuestionnaireResponded", questionnaireResponded);
    },
  },
  modules: {},
  getters: {
    getId: state => state.user.id,
    getUser: state => state.user,
    getLoggedIn: state => state.loggedIn
  }
});
