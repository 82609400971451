






























































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import QuestionnaireExplorer from "@/components/QuestionnaireExplorer.vue";
import EngineExplorer from "@/components/EngineExplorer.vue";
import CoverExplorer from "@/components/CoverExplorer.vue";
import UserManagement from "@/components/UserManagement.vue";
import JobBoardManagement from "@/components/JobBoardManagement.vue";
import CreditsViewer from "@/components/CreditsViewer.vue";
import AdminMetadataUpdater from "@/components/AdminMetadataUpdater.vue";
import LocationExplorer from "@/components/LocationExplorer.vue";
import JobMetadataUpdater from "@/components/JobMetadataUpdater.vue";
import QuestionnaireSectActiv from "@/components/DeSiSectActiv/QuestionnaireSectActiv.vue";
import NeuroscienceEvaluation from "@/components/NeuroscienceEvaluation/NeuroscienceEvaluation.vue";
import EngineChecker from "@/components/EngineChecker.vue";

import { mapGetters } from "vuex";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {
    QuestionnaireExplorer,
    EngineExplorer,
    UserManagement,
    CreditsViewer,
    AdminMetadataUpdater,
    JobMetadataUpdater,
    LocationExplorer,
    JobBoardManagement,
    CoverExplorer,
    QuestionnaireSectActiv,
    EngineChecker,
    NeuroscienceEvaluation
  },
  computed: mapGetters(["getUser"])
})
export default class DashboardAdmin extends Vue {}
