






























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

interface Recipient {
  email: string;
}

interface Price {
  name: string;
  value: number | null;
}

interface DeviationWeights {
  index: number;
  value: number | null;
}

interface MurrayWeights {
  index: number;
  value: number | null;
}


@Component
export default class AdminMetadataUpdater extends Vue {
  recipients: Array<Recipient> = [];
  prices: Array<Price> = [];
  updated = true;
  pricesUpdated = true;
  deviation_weights: Array<DeviationWeights> = [];
  deviation_weightsUpdated = true;

  murray_weights: Array<MurrayWeights> = []; 
  murray_weightsUpdated = true;

  webAddressMetier: any = [];
  WebAddressMetierUpdated = true;

  updatePrices() {
    const d = {};
    this.prices.forEach(p => {
      d[p.name] = p.value != null ? Number((p.value * 100).toFixed(2)) : null;
    });
    axios
      .post("/api/metadata", { prices: d })
      .then(ans => {
        this.pricesUpdated = true;
      })
      .catch(console.log);
  }

  updateRecipients() {
    axios
      .post("/api/metadata", {
        email: {
          RECIPIENTS: this.recipients.filter(e => e.email).map(e => e.email)
        }
      })
      .then(ans => {
        this.updated = true;
      })
      .catch(console.log);
  }

  updateDeviation_weights(){


    const d = {};
    this.deviation_weights.forEach(p => {
      d[p.index] = p.value != null ? Number(p.value) : null;
    });

    axios
      .post("/api/metadata", { DeviationWeights: d })
      .then(ans => {
        this.deviation_weightsUpdated = true;
        
      })
      .catch(console.log);

  }

  updateMurray_weights(){

    const d = {};
    this.murray_weights.forEach(p => {
      d[p.index] = p.value != null ? Number(p.value) : null;
    });

    axios
      .post("/api/metadata", { MurrayWeights: d })
      .then(ans => {
        this.murray_weightsUpdated = true;
        
      })
      .catch(console.log);

  }

  get emptyRecipient() {
    return this.recipients.filter(e => e.email.length == 0).length > 0;
  }

  get emptyDeviation_weight() {
    return this.deviation_weights.filter(e => e.value == -1).length > 0;
    
  }

  get emptyMurray_weight() {
    return this.murray_weights.filter(e => e.value == -1).length > 0;
    
  }


  addRecipient() {
    if (!this.emptyRecipient) {
      this.recipients.push({ email: "" });
    }
  }


  addDeviation_weight(){
    if (!this.emptyDeviation_weight) {
      this.deviation_weights.push( {index: this.deviation_weights.length, value: -1});
    }
  }

  addMurray_weight(){
    if (!this.emptyMurray_weight) {
      this.murray_weights.push( {index: this.murray_weights.length, value: -1});
    }
  }


  removeRecipient(email) {
    this.recipients = this.recipients.filter(e => e.email != email);
  }

  removeDeviationWeight(value){
    this.deviation_weights = this.deviation_weights.filter(e => e.value != value);

  }

  removeMurrayWeight(value){
    this.murray_weights = this.murray_weights.filter(e => e.value != value);
  }

  fetchData() {

    axios
    .get("/api/webAddressMetier")
    .then(ans => {
      this.webAddressMetier = ans.data;
    })

    return axios
      .get("/api/metadata")
      .then(ans => {
        
        this.deviation_weights = Object.entries(ans.data.DeviationWeights || {}).map( p => {

          const dev: DeviationWeights = {
            index: Number(p[0]),
            value: p[1] ? (p[1] as number) : null
          };
          return dev;

        });

        this.murray_weights = Object.entries(ans.data.MurrayWeights || {}).map( p => {
          const dev: MurrayWeights = {
            index: Number(p[0]),
            value: String(p[1]) ? (p[1] as number) : null
          };
          return dev;

        });  
        
        this.recipients = ans.data.email.RECIPIENTS.map(e => {
          return { email: e };
        });

        this.prices = Object.entries(ans.data.prices || {}).map(p => {
          const price: Price = {
            name: p[0],
            value: p[1] ? (p[1] as number) : null
          };
          if (price.value) {
            price.value = Number((price.value / 100).toFixed(2));
          }
          return price;
        });

      })
      .catch(console.log);
  }

  @Watch("recipients", { deep: true })
  rchd() {
    this.updated = false;
  }

  @Watch("prices", { deep: true })
  pchd() {
    this.pricesUpdated = false;
  }

  @Watch("deviation_weights", { deep: true })
  dwchd() {
    this.deviation_weightsUpdated = false;
  }


  @Watch("murray_weights", { deep: true })
  murrayWeightchd() {
    this.murray_weightsUpdated = false;
  }


  @Watch("webAddressMetier", { deep: true })
  webAddresschd() {
    this.WebAddressMetierUpdated = false;
  }

  get emptyAddress() {
    return this.webAddressMetier.filter(e => (e.webAdderess == "" || e.description == "")).length > 0;
  }

  addWebAddressMetier(){
    if (!this.emptyAddress) {
      this.webAddressMetier.push({ webAdderess: "", description: ""});
    }    
  }

  removeWebAddressMetier(wAddress){
    this.webAddressMetier = this.webAddressMetier.filter(e => e.description != wAddress.description);
  }

  updateWebAddressMetier(){

    axios
      .post("/api/webAddressMetier",{}, {params: { addresses: this.webAddressMetier, addressesNBR : this.webAddressMetier.length}})
      .then(ans => {
        this.WebAddressMetierUpdated = true;
        
      })
      .catch(console.log);

  }


  mounted() {
    this.fetchData().then(() => {
      this.updated = true;
      this.deviation_weightsUpdated = true;
      this.murray_weightsUpdated = true;
    });
  }
}
