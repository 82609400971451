












































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Plotly } from 'vue-plotly';


interface PosteDetails{
  keys: string[];
  candidat: number[];
  poste: number[];
}

interface PosteElement{
  candidat: number;
  poste: number;
}

@Component({
  components: { Plotly }
})
export default class PosteMatching extends Vue {
  @Prop()
  details: any;
  
  @Prop()
  name: string | undefined;
  
  @Prop()
  total: any;

  @Prop({default: false})
  showTable!: boolean;

  

  get dataVals(){
    return (!this.details)?{keys: [], poste: [], candidat: []}:{
      keys: Object.keys(this.details),
      poste: Object.values(this.details).map(e => (e as PosteElement).poste * 100),
      candidat: Object.values(this.details).map(e => (e as PosteElement).candidat * 100),
    }
  }

  
  
  layout = {
    legend: {
      ranchor: 'bottom',
      x: 200,
      y: 900,
    },
    margin:{
      l: 120,
      r: 100,
      b: 50,
      t: 50,
    },
    polar: {
      radialaxis: {
        visible: true,
        range: [0, 100],
        automargin: true,
      }
    }
  }

  sum(arr){
    return arr.reduce((a, b) => a+b, 0)
  }

  mean(arr){
    return arr.length==0?NaN:this.sum(arr)/arr.length
  }
  
  
  get ecartPoste(){
    return this.mean(Object.values(this.details).map(e=>(e as any).candidat)) - this.mean(Object.values(this.details).map(e=>(e as any).poste));
  }
  
  circleArray(e){
    return e.length>0?e.concat(e[0]):[]
  }
  
  values(what){
    return this.circleArray(this.dataVals[what])
  }
  
  get traces(){
    return [
      {
        type: 'scatterpolar',
        r: this.values("poste"),
        theta: this.values("keys"),
        fill: 'toself',
        name: "Requis pour ce métier",
        line: {
          color: "#e04793aa",
        },
        fillcolor: "#e0479333",
      },
      {
        type: 'scatterpolar',
        r: this.values("candidat"),
        theta: this.values("keys"),
        fill: 'toself',
        name: 'Mon score',
        color: "#1793d0",
        line: {
          color: "#1793d0",
        },
      },
    ]
  }
  
  formatPercent(v) {
    if (v == undefined || v == null) {
      return "NaN";
    } else {
      return (v * 100).toFixed(0) + " %";
    }
  }
}
