

















import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

@Component
export default class Home extends Vue {
  message = "Welcome Home";
  baseurl = process.env.BASE_URL;

  questionnaireTypeList: Array<string> = [];

  mounted() {
    axios
      .get("/api/questionnaire-type-list")
      .then(ans => {
        this.questionnaireTypeList = ans.data;
      })
      .catch(console.log);
  }
}
