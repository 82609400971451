

























































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FormEntreprise } from "@/types";
import ListInput from "@/components/ListInput.vue";
import FormElement from "@/components/jobboard/FormElement.vue";
import FormElementTransition from "@/components/jobboard/FormElementTransition.vue";
import PaginatedViewer from "@/components/PaginatedViewer.vue";

@Component({
  components: { ListInput, PaginatedViewer, FormElement, FormElementTransition }
})
export default class JobBoardFormEntrepriseSection extends Vue {
  @Prop()
  value!: any;

  edit = { job: {} };

  form: FormEntreprise | null = null;

  @Watch("form", { deep: true })
  formchgd(v) {
    this.$emit("input", v);
  }

  @Watch("value", { deep: true })
  valchgd(v) {
    if (v) {
      this.form = v;
    }
  }

  mounted() {
    if (this.value) {
      this.form = this.value;
    }
  }
}
