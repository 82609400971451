





































import { Component, Vue } from "vue-property-decorator";
import EnduserQuestionnaireExplorer from "@/components/EnduserQuestionnaireExplorer.vue";
import CreditsViewer from "@/components/CreditsViewer.vue";
import ResumeManager from "@/components/ResumeManager.vue";
import { mapGetters } from "vuex";

@Component({
  components: { EnduserQuestionnaireExplorer, ResumeManager, CreditsViewer },
  computed: mapGetters(["getUser"])
})
export default class DashboardEnduser extends Vue {
  mounted(){
    if(this.$route.query['test-send-success']){
      this.$toast.success("Merci ! Votre test a bien été envoyé.")
    }
  }
}
