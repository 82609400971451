




































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Engine } from "@/types";
import FileUploader from "@/components/FileUploader.vue";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: { FileUploader }
})
export default class EngineExplorer extends Vue {
  private engine: Engine | null = null;
  private loadingEngine = false;
  
  startUpload() {
    this.loadingEngine = true;
  }

  finishUpload() {
    this.loadingEngine = false;
    this.$toast.info("Moteur mis à jour avec succès!");
    this.getEngine();
  }

  errorUpload() {
    this.loadingEngine = false;
  }

  private downloadEngine(e) {
    window.open(process.env.BASE_URL + "download-engine/?id=" + e.id, "_blank");
  }

  private getEngine() {
    return axios
      .get("/api/engine")
    .then(ans => {
      console.log('-------- engine loaded --------')
        console.log(ans.data);
        ans.data.engine.added = new Date(ans.data.engine.added);
        this.engine = ans.data.engine;
      })
      .catch(console.log);
  }

  mounted() {
    this.getEngine();
  }
}
