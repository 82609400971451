























































































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { FormSavoirFaire } from "@/types";
import ListInput from "@/components/ListInput.vue";
import FormElement from "@/components/jobboard/FormElement.vue";
import FormElementTransition from "@/components/jobboard/FormElementTransition.vue";
import PaginatedViewer from "@/components/PaginatedViewer.vue";
// import schoolList from "@/data/JobBoardData.js";

@Component({
  components: { ListInput, PaginatedViewer, FormElement, FormElementTransition }
})
export default class JobBoardFormSavoirFaireSection extends Vue {
  // degreeList = schoolList;

  @Prop()
  value!: any;

  @Prop({ default: "" })
  title: string | undefined;

  employeurs: Array<string> = [];
  employeursPast: Array<string> = [];
  newMobilite: any = [];
  form: FormSavoirFaire | null = null;
  edit = { savoirFaire: {} };
  intrvl: any = null;

  @Watch("form", { deep: true })
  formchgd(v) {
    if (v) {
      // console.log('form section emits')
      // console.log(v.degrees)
      this.$emit("input", v);
    }
  }

  @Watch("value", { deep: true })
  valchgd(v) {
    // console.log('JOBBOARD SECTION VALUE CHANGED: ' + JSON.stringify(v))
    if (v) {
      this.form = v;
    }
  }

  mounted() {
    if (this.value) {
      this.form = this.value;
      // console.log(JSON.stringify(this.value))
    }
  }
}
