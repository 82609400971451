













































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { mapGetters } from "vuex";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
    computed: mapGetters(["getUser"]),
})

export default class NeuroscienceEvaluationQuestionnaire extends Vue{
    
    isAdmin = false;
    getUser : any;
    backupServer = true;
    viewOnly = false;
    shouldBackup = true;
    qId: string | ( string | null)[] | undefined = undefined;
    loadingStatus = "";
    testBackupButtonText = "Sauvegarder le test";
    questionnaire: any = [];
    referrer: any = {};
    errormsg = "";
    showErrorsOnly = false;
    saved = false;
    triedValidating = false;

    problemDescription = "";

    showHelpModal = false;
    state = false;

    get testIsReady(){
        let someHasIssue = false;
        this.questionnaire.forEach(item => {
            if (item.questions.some(this.answerHasIssue)){
                someHasIssue = true;
            }
        });

        return (
            this.questionnaire && !someHasIssue
        );
    }

    resetq(q) {
        if (this.isAdmin) {
        q.choice = -1;
        }
    }

    resetQuestionnaire(fill) {

    }

    answerHasIssue(q) {
        return (q.choice != undefined && q.choice == -1);
    }

    getItemChoices(choicesStr){
        return choicesStr.split(",");
    }

    setHTTPErr(err){
        this.errormsg = err.response.data.message;
        this.$toast.error(err.response.data.message || "err");
    }

    async fetchQuestionnaireId() {
        const routeQid = this.$route.query.id;
        if (routeQid) {
            return routeQid;
        } else {
            this.setHTTPErr("Aucun questionnaire n'est trouvé!");
        }
    }

    async loadQuestionnaire(){

        let rights = "view";

        try {
            this.qId = await this.fetchQuestionnaireId();
        } catch (error) {
            this.$toast.error("Une erreur est survenue: " + error);
        }

        if (this.getUser.privileges == "enduser") {
            try {
                
                const rs = await axios.get("/api/get-EvaluationNeuroscienceQuestionnaire-rights", {
                    params: { id: this.qId }
                });                
                rights = rs.data;

            } catch (error) {
                if (this.$route.query.id) {
                    this.$router.push({ query: {} });
                    location.reload();
                }
                rights = "nothing";
            }
        }

        this.viewOnly = rights == "view";
        this.loadingStatus = "loading";

        return axios
        .get("/api/EvaluationNeuroscienceQuestionnaire", {
            params: { id: this.qId }
        })
        .then(ans => {

            this.referrer = ans.data.referrer;
            this.questionnaire = ans.data.data;
            this.backupServer = true;
            this.testBackupButtonText = "Test sauvegardé";
            this.loadingStatus = "ready";
        })
        .catch(err => {
            this.loadingStatus = "error";
            this.setHTTPErr(err);
        });
    }

    saveTest(finished, hidden){

        if (this.viewOnly && !this.isAdmin) { return;}
        if (!hidden) { this.testBackupButtonText = "Sauvegarde en cours..."; }
        this.backupServer = false;
        if (finished) { this.triedValidating = true;}

        if (!finished || this.testIsReady) {
            if (this.questionnaire){
                if (finished) {
                    this.state = true;           
            }}

            if(finished){ this.shouldBackup = false;}
            
            axios
            .post("/api/EvaluationNeuroscienceQuestionnaire", {
                data: this.questionnaire,
                id: this.qId,    
                state: this.state             
            })
            .then(ans => {
                
                this.backupServer = true;
                if (!hidden) { 
                    this.testBackupButtonText = "Test sauvegardé";}
                if (finished) {
                    this.saved = true;
                    this.viewOnly = true;
                    this.$router.push('/dashboard?test-send-success=ok')
                }
            })
            .catch(err => {
                if (!hidden) {
                    this.setHTTPErr(err);
                    this.backupServer = false;
                    this.testBackupButtonText = "Sauvegarder le test";
                }
            });

        }else{
            this.errormsg = "Merci de répondre à toutes les questions";
            this.$toast.warning("Merci de répondre à toutes les questions");
            this.backupServer = false;
            this.testBackupButtonText = "Sauvegarder le test";
        }
    }

    async init(){
        if (this.getUser.id) {
            await this.loadQuestionnaire();
        }

        setInterval(() => {
            if (!this.backupServer && !this.viewOnly && this.shouldBackup) {
                this.saveTest(false, true);
            }
        }, 10000);
    }


    handleHelpOK() {
        axios
        .post("/api/EvaluationNeurosciencehelp", {
            id: this.qId,
            description: this.problemDescription
        })
        .then(resp => {
            console.log(resp);
            this.$toast.info(
                "Votre demande d'aide a été envoyée. Nous vous contacterons par email dans les plus brefs délais."
            );
            this.showHelpModal = false;
        })
        .catch(err => {
            this.setHTTPErr(err);
            this.showHelpModal = false;
        });
    }



    @Watch("triedValidating")
    tvchanged(v) {
        if (v && this.showErrorsOnly) {
            this.showErrorsOnly = false;
        }
    }

    @Watch("showErrorsOnly")
    veochanged(v) {
        if (v) {
            this.errormsg = "";
            window.scrollTo(0, 0);
            this.$toast.info(
                'Vous êtes maintenant en mode "visualisation des erreurs", seulement les réponses manquantes sont affichées.'
            );
        }
    }

    @Watch("questionnaire", { deep: true })
    questionnaireChanged(v) {
        if (v && !this.viewOnly) {
            this.backupServer = false;
            this.testBackupButtonText = "Sauvegarder le test";
        }
    }

    @Watch("getUser.id")
    userIdChanged(id, oldid) {
        console.log("user id changed: " + oldid + " -> " + id);
        this.loadQuestionnaire();
    }

    @Watch("saved")
    savedChanged(v) {
        if (v) {
        this.$toast.success(
            "Le questionnaire a été envoyé. Vous recevrez vos résultats dès que possible."
        );
        }
    }

    @Watch("viewOnly")
    viewOnlyChanged(v) {
        if (v && this.getUser.privileges == "enduser") {
        setTimeout(() => {
            this.$toast.info(
            'Vous êtes désormais en mode "lecture seule". Vous pouvez voir vos réponses, mais pas les modifier.',
            {timeout: 10000}
            );
        }, 2000);
        }
    }


    mounted(){
        this.isAdmin = this.$route.query.admin != undefined; 
        this.init()
        .then(console.log)
        .catch(console.error);    
    }

}
