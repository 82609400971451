








































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

interface Plot {
  margin: number;
}

@Component
export default class PlotAscendanceOuverture extends Vue {
  @Prop()
  scores: any;
  
  plot: Plot = {
    margin: 0.11
  };

  mapCoords(percent) {
    return (
      (this.plot.margin * 100) / 2 + (1 - this.plot.margin) * percent + "%"
    );
  }

}
