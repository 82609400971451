


























































import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { Order } from "@/types";
import axios from "axios";

declare global {
  interface Window {
    KR: any;
  }
}

@Component({
  computed: mapGetters(["getUser"])
})
export default class Paiement extends Vue {
  showForm = false;
  paiementFinished = false;
  errormsg = "";
  proceedClicked = false;
  infomsg = "Chargement du module de paiement...";
  KR = false;
  intervalKRInit: any | undefined;

  @Prop()
  mode: string | undefined;

  @Prop()
  orderDetails: any | undefined;

  @Prop()
  order: Order | undefined;

  onPaid(e) {
    console.log("---- PAID ----");
    this.infomsg = "Félicitations, votre paiement a été effectué avec succès !";
    this.paiementFinished = true;
    this.showForm = false;
    console.log(e);
    this.$emit('success')
    // if(this.mode=="test"){
    //   console.log("sending ipn...")
    //   axios.post('/api/pay/ipn', e.clientAnswer)
    //   .then(resp => {
    //     console.log(resp);
    //     this.infomsg += " (ipn transmis)";
    //     window.KR.removeForms();
    //     this.$emit('success')
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     this.$emit('error')
    //     this.errormsg = "erreur lors de la transmission de l'ipn."
    //   })
    // }
  }

  get isProd() {
    return process.env.NODE_ENV != "test" && this.mode != "test";
  }

  onCheckout() {
    console.log("CHECKOUT");
    console.log(this.mode);
    console.log(this.order);
    this.proceedClicked = true;

    console.log(window.KR);
    axios
      .post("/api/pay/get-form-token", {
        order: this.order,
        mode: this.isProd ? "prod" : "test"
      })
      .then(resp => {
        this.showForm = true;
        console.log(resp);
        window.KR.setFormToken(resp.data.formToken);
        window.KR.onSubmit(this.onPaid);
      })
      .catch(err => {
        console.log(err);
        this.$emit("error");
        this.errormsg = err.response;
      });
  }

  insertElement(name, attrs) {
    console.log(attrs);
    const m = document.createElement(name);
    for (const [k, v] of Object.entries(attrs)) {
      m.setAttribute(k, v);
    }
    document.head.appendChild(m);
    console.log(m);
  }
  mounted() {
    this.intervalKRInit = setInterval(() => {
      console.log("intervalKR called");
      console.log(window.KR);
      if (window.KR) {
        this.KR = true;
        console.log("KR initialized OK");
        this.infomsg = "";
        window.clearInterval(this.intervalKRInit);
      }
    }, 1500);
    const m = document.createElement("meta");
    m.setAttribute("http-equiv", "Content-Security-Policy");
    m.setAttribute(
      "content",
      "style-src 'self' https://fonts.googleapis.com https://api.systempay.fr 'unsafe-inline'; font-src 'self' https://fonts.gstatic.com https://api.systempay.fr 'unsafe-inline';"
    );
    document.head.appendChild(m);
    // this.insertElement("meta", {
    //   'http-equiv': "Content-Security-Policy",
    //   content: "default-src 'self' https://api.systempay.fr data: gap: https://code.jquery.com 'unsafe-eval'; style-src 'self' 'unsafe-inline' https://api.systempay.fr; script-src 'self' https://api.systempay.fr/ 'unsafe-inline' 'unsafe-eval';  media-src *"
    // });

    const s = document.createElement("script");
    s.setAttribute(
      "src",
      "https://api.systempay.fr/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"
    );
    const pubkey = this.isProd
      ? process.env.VUE_APP_SYSTEM_PAY_PUBKEY_PROD
      : process.env.VUE_APP_SYSTEM_PAY_PUBKEY_TEST;
    console.log(process.env);
    console.log("MODE: " + process.env.NODE_ENV + " -> pubkey == " + pubkey);
    s.setAttribute("kr-public-key", pubkey);
    // s.setAttribute('kr-post-url-success', "desi.alorem.fr/paiement-test?status=successnav")
    document.head.appendChild(s);

    this.insertElement("link", {
      rel: "stylesheet",
      href:
      "https://api.systempay.fr/static/js/krypton-client/V4.0/ext/classic-reset.css"
    });
    this.insertElement("script", {
      src:
        "https://api.systempay.fr/static/js/krypton-client/V4.0/ext/classic.js"
    });
  }
}
