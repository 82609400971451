
































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Cover } from "@/types";
import FileUploader from "@/components/FileUploader.vue";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: { FileUploader }
})

export default class CoverExplorer extends Vue{

    private cover: Cover | null = null;
    private loadingCover = false;
    
    startUpload() {
        this.loadingCover= true;
    }

    finishUpload() {
        this.loadingCover = false;
        this.$toast.info("Cover DeSI-Talents mis à jour avec succès!");
        this.getCover();
    }

    errorUpload() {
        this.loadingCover = false;
    }

    private downloadCover(c) {
        window.open(process.env.BASE_URL + "download-cover_DeSI_Talents/?id=" + c.id, "_blank");

    }

    private getCover() {

        return axios
        .get("/api/coverPage_DeSI_Talents")
        .then(ans => {
            console.log(ans)
            ans.data.cover.added = new Date(ans.data.cover.added);
            this.cover = ans.data.cover;

        })
        .catch(console.log);

    }

    /////////////////////////////////////////////////////////////

    private cover2: Cover | null = null;
    private loadingCover2 = false;
    
    startUpload2() {
        this.loadingCover2= true;
    }

    finishUpload2() {
        this.loadingCover2 = false;
        this.$toast.info("Cover DeSI-Activités mis à jour avec succès!");
        this.getCover2();
    }

    errorUpload2() {
        this.loadingCover2 = false;
    }

    private downloadCover2(c) {
        window.open(process.env.BASE_URL + "download-cover_DeSI_activities/?id=" + c.id, "_blank");

    }

    private getCover2() {

        return axios
        .get("/api/coverPage_DeSI_activities")
        .then(ans => {
            console.log("coooover");
            console.log(ans);

            ans.data.cover.added = new Date(ans.data.cover.added);
            this.cover2 = ans.data.cover;

        })
        .catch(console.log);

    }

    mounted() {
        this.getCover();
        this.getCover2();
    }

}

