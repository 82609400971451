























import { Component, Vue, Watch } from "vue-property-decorator";
import DashboardAdmin from "@/components/DashboardAdmin.vue";
import DashboardClient from "@/components/DashboardClient.vue";
import DashboardEnduser from "@/components/DashboardEnduser.vue";
import Login from "@/components/Login.vue";
import { mapGetters } from "vuex";

@Component({
  components: { DashboardAdmin, DashboardClient, DashboardEnduser, Login },
  computed: mapGetters(["getUser", "getLoggedIn"])
})
export default class Admin extends Vue {
  err: string | null = null;
  info: string | null = null;
  getUser: any;
  private privileges = "";

  get componentName() {
    return (
      "Dashboard" +
      this.privileges.charAt(0).toUpperCase() +
      this.privileges.slice(1)
    );
  }

  setErr(e) {
    this.err = e;
  }

  setInfo(e) {
    this.info = e;
  }

  setUser() {
    if (this.getUser) {
      this.privileges = (this.$route.query.viewAs ||
        this.getUser.privileges ||
        "") as string;
      if (this.$route.query.viewAs) {
        const u = this.getUser;
        console.log("USER ----->>>>>");
        console.log(u);
        console.log(this.privileges);
        u.privileges = this.privileges;
        this.$store
          .dispatch("setUser", u)
          .then(() => {
            console.log("DONE");
            console.log(this.getUser);
          })
          .catch(console.log);
      }
    }
  }

  @Watch("getUser.privileges")
  getUserUpdated() {
    this.setUser();
  }

  mounted() {
    this.setUser();

    if (this.$route.query.activate == "success") {
      this.info = "Félicitation ! Votre compte est désormais actif.";
      setTimeout(() => {
        this.info = "";
      }, 5000);
    }
  }
}
