

































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import FileUploader from "@/components/FileUploader.vue";

axios.defaults.baseURL = process.env.BASE_URL;

interface Resume {
  date: Date;
  filename: string;
}

@Component({
  components: { FileUploader }
})
export default class ResumeManager extends Vue {
  private resume: Resume | null = null;
  info = "";
  baseurl = process.env.BASE_URL;

  fetchData() {
    axios
      .get("/api/resume")
      .then(ans => {
        console.log("resume:");
        console.log(ans);
        this.resume = ans.data;
      })
      .catch(() => {
        console.log("no resume found.");
      });
  }

  download() {
    window.open(this.baseurl + "download-resume", "_blank");
  }

  startUpload() {
    this.info = "";
  }

  finishUpload() {
    this.info = "Votre CV a été envoyé !";
    this.$toast.info("Votre CV a été envoyé !");
    this.fetchData();
  }

  errorUpload(message) {
    if (message) {
      this.info = "Une erreur est survenue : " + message;
    } else {
      this.info = "";
    }
  }

  mounted() {
    this.fetchData();
  }
}
