






















































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { mapGetters } from "vuex";
import { StringOrNumber } from "vee-validate/dist/types/types";

axios.defaults.baseURL = process.env.BASE_URL;


interface DeSIACTIVRadioQuestions{
    choice : number;
}

interface DeSIACTIVContextQuestions{
    response : string;
}

interface QuestionnaireData {
  title: string;
  radioQuestions: [DeSIACTIVRadioQuestions];
  contextQuestions: [DeSIACTIVContextQuestions];
}

@Component({
    computed: mapGetters(["getUser"]),
})

export default class DeSI_ACTIV extends Vue{
    
    viewOnly = false;
    isAdmin = false;
    errormsg = "";
    showHelpModal = false;
    problemDescription = "";
    backupServer = true;
    getUser : any;
    testBackupButtonText = "Sauvegarder le test";
    triedValidating = false;
    showErrorsOnly = false;
    qId: string | undefined = undefined;

    shouldBackup = true;
    loadingStatus = "";
    referrer: any = {};
    questionnaire: QuestionnaireData | null = null;
    saved = false;
    choices = []

    postes_List: any = [];
    diplomes_List: any = [];
    secteurs_List: any = [];

    selectedSecteurInList: string = "";

    secteurMatchingDict = {};
    getSecteurById = {};


    get_label(question){

        // when the values are predefined in list
        if (question.selectedlist != ""){
   
            // get the list from db: 
            if (question.selectedlist == 'postes'){
                
                    return "metier";

            }
            
            else if (question.selectedlist == 'diplomes'){

                return "école";
            }
            
            else if (question.selectedlist == 'DeSIACTIVsecteur'){
 
                return "secteur";
            }
        }
        else if (question.responsesList != ''){
            return 'value';
        }

    }


    get_options(question){
    
        // when the values are predefined in list
        if (question.selectedlist != ""){
   
            // get the list from db: 
            if (question.selectedlist == 'postes'){
                
                if (this.selectedSecteurInList != ''){
                    
                    let posts: any[] = [];
                    
                    const secteurselectedID = this.secteurMatchingDict[this.selectedSecteurInList];
                    
                    if (secteurselectedID && secteurselectedID.length > 0){

                        for (let i = 0; i < secteurselectedID.length; i++){

                            posts = this.postes_List.filter(
                                    e => e.secteurs == this.getSecteurById[secteurselectedID[i]]
                                );

                        }

                    }
                    else{
                        posts = this.postes_List;
                    }
                    return posts;
                }

            }
            
            else if (question.selectedlist == 'diplomes'){
                // if diplomes: get "école" from schools
                return this.diplomes_List;
            }
            
            else if (question.selectedlist == 'DeSIACTIVsecteur'){

                // if DeSIACTIVsecteur: get secteur from DeSIACTIVsecteurs
                this.selectedSecteurInList = question.response.id;  
                return this.secteurs_List;
            }
        }
        else if (question.responsesList != ''){
            // list of values separated by commas: ,
            const defaultval: any[] = [{text:"Sélectionner une options", value: '', disabled: true}];
            var str_array = question.responsesList.split(',');
            return defaultval.concat(str_array.filter(e => e != "").map( e  => {
                return {
                    text: e,
                    value: e,
                }
            }));
        }
        
        
    }
    

    closeHelpModal(){
        this.showHelpModal = false;
    }

    handleHelpOK(){
        axios
        .post("/api/DeSIACTIVhelp", {
            id: this.qId,
            description: this.problemDescription
        })
        .then(resp => {
            this.$toast.info(
            "Votre demande d'aide a été envoyée. Nous vous contacterons par email dans les plus brefs délais."
            );
            this.closeHelpModal();
        })
        .catch(err => {
            this.setHTTPErr(err);
            this.closeHelpModal();
        });
    }

    // test
    saveTest(finished, hidden){

        if (this.viewOnly && !this.isAdmin) { return;}
        
        if (!hidden) { this.testBackupButtonText = "Sauvegarde en cours..."; }
        this.backupServer = false;
        
        if (finished) { this.triedValidating = true;}
        
        if (!finished || this.testIsReady) {
            if (this.questionnaire){
                if (!hidden) { 
                    this.questionnaire['state'] = 'started';
                } if (finished) {
                    this.questionnaire['state'] = 'finished';
                }                
            }

            if(finished){ this.shouldBackup = false;}
            axios
            .post("/api/DeSIACTIVquestionnaire", {
                data: this.questionnaire,
                id: this.qId,                
            })
            .then(ans => {
                console.log(ans);
                this.backupServer = true;
                if (!hidden) { 
                    this.testBackupButtonText = "Test sauvegardé";}
                if (finished) {
                    this.saved = true;
                    this.viewOnly = true;
                    this.$router.push('/dashboard?test-send-success=ok')
                }
            })
            .catch(err => {
                if (!hidden) {
                    this.setHTTPErr(err);
                    this.backupServer = false;
                    this.testBackupButtonText = "Sauvegarder le test";
                }
            });

        }else{
            this.errormsg = "Merci de répondre à toutes les questions";
            this.$toast.warning("Merci de répondre à toutes les questions");
            this.backupServer = false;
            this.testBackupButtonText = "Sauvegarder le test";
        }
    }

    // test
    get testIsReady() {
        return (
            this.questionnaire && 
            !this.questionnaire.radioQuestions.some(this.answerHasIssue)
        );
    }

    answerHasIssue(q) {
        return (q.choice != undefined && q.choice == -1) || (q.obligatory == 'true' && !(q.response != '' || q.fieldAnswer != '') )
    }

    //refaire et test
    resetQuestionnaire(fill){
        if (fill) {

            if (this.questionnaire) {
                this.triedValidating = false;


                this.questionnaire.radioQuestions.map(q => {
                    q.choice = -1;
                    }
                );
            }
        } else {
            this.loadQuestionnaire()
            .then(() => {
                if (this.questionnaire) {
                    this.triedValidating = false;
                }
            })
        }
    }

    setHTTPErr(err){
        console.log("$$$$$$$$ error $$$$$$$$");
        this.errormsg = err.response.data.message;
        this.$toast.error(err.response.data.message || "err");
    }

    
    async fetchQuestionnaireId() {
        const routeQid = this.$route.query.id;
        if (routeQid) {
            return routeQid;
        } else {
            try {
                const resp = await axios.get("/api/current-DeSIACTIVquestionnaire-id", {
                    params: { "id": this.$route.query.id }
                });
                this.$router.push({ query: { id: resp.data.id } });
                return resp.data.id;
            } catch (err) {
                this.setHTTPErr(err);
            }
        }
    }

    async loadQuestionnaire(){

        let rights = "view";

        try {
            this.qId = await this.fetchQuestionnaireId();
        } catch (error) {
            this.$toast.error("Une erreur est survenue: " + error);
        }

        if (this.getUser.privileges == "enduser") {
            console.log("**** **** enduser **** ****");
            try {
                console.log("**** questionnaire rights ****");
                
                const rs = await axios.get("/api/get-DeSIACTIVquestionnaire-rights", {
                    params: { id: this.qId }
                });
                
                console.log(rs);
                rights = rs.data;

            } catch (error) {
                if (this.$route.query.id) {
                    this.$router.push({ query: {} });
                    location.reload();
                }
                rights = "nothing";
            }
        }

        this.viewOnly = rights == "view";
        this.loadingStatus = "loading";

        return axios
        .get("/api/DeSIACTIVquestionnaire", {
            params: { id: this.qId }
        })
        .then(ans => {
            console.log("QUESTIONNAIRE");
            const q = ans.data.data;
            this.referrer = ans.data.referrer;
            this.questionnaire = q;
            this.backupServer = true;
            this.testBackupButtonText = "Test sauvegardé";
            this.loadingStatus = "ready";
            console.log(this.questionnaire)
        })
        .catch(err => {
            this.loadingStatus = "error";
            this.setHTTPErr(err);
        });
    }

    callLists(){
    
        axios.get("/api/jobs-SelectedList")
        .then(ans => 
            {   
                this.postes_List = ans.data;
            }
        )


        axios.get("/api/schools-SelectedList")
        .then(ans => 
            {
                this.diplomes_List = ans.data;
            }
        )

        axios.get("/api/DeSIACTIVsecteurs-SelectedList")
        .then(ans => 
            {
                this.secteurs_List = ans.data;
            }
        )


        axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteursMatching' }})
        .then(ans => {

            for (let i=0; i < ans.data.length; i++){
                this.secteurMatchingDict[ans.data[i].desiACTIV] = ans.data[i].refMetiers;
            }
        })

        axios.get("/api/get_DeSIACTIV_data", {params : { view: 'secteurs' }})
        .then(ans => {

            for (let i=0; i < ans.data.length; i++){
                this.getSecteurById[ans.data[i]._id] = ans.data[i].secteur;
            }
        })
        .catch(console.log);     

    }

    getChoices(){

        axios.get("/api/DeSIACTIVChoices")
        .then(ans => 
            {this.choices = ans.data;}
        )
    }

    async init(){
        if (this.getUser.id) {
            await this.loadQuestionnaire();
        }

        this.callLists();
        this.getChoices();

        setInterval(() => {
            if (!this.backupServer && !this.viewOnly && this.shouldBackup) {
                console.log('backing up...')
                this.saveTest(false, true);
            }
        }, 10000);
    }

    @Watch("triedValidating")
    tvchanged(v) {
        if (v && this.showErrorsOnly) {
        this.showErrorsOnly = false;
        }
    }

    @Watch("showErrorsOnly")
    veochanged(v) {
        if (v) {
        this.errormsg = "";
        window.scrollTo(0, 0);
        this.$toast.info(
            'Vous êtes maintenant en mode "visualisation des erreurs", seulement les réponses manquantes sont affichées.'
        );
        }
    }

    @Watch("questionnaire", { deep: true })
    questionnaireChanged(v) {
        // console.log('questionnaire changed')
        if (v && !this.viewOnly) {
        // console.log('backing up localStorage')
        this.backupServer = false;
        this.testBackupButtonText = "Sauvegarder le test";
        // localStorage["questionnaire_" + this.qType] = JSON.stringify(v);
        }
    }

    @Watch("getUser.id")
    userIdChanged(id, oldid) {
        console.log("user id changed: " + oldid + " -> " + id);
        this.loadQuestionnaire();
    }

    @Watch("saved")
    savedChanged(v) {
        if (v) {
        this.$toast.success(
            "Le questionnaire a été envoyé. Vous recevrez vos résultats dès que possible."
        );
        }
    }

    @Watch("viewOnly")
    viewOnlyChanged(v) {
        if (v && this.getUser.privileges == "enduser") {
        setTimeout(() => {
            this.$toast.info(
            'Vous êtes désormais en mode "lecture seule". Vous pouvez voir vos réponses, mais pas les modifier.',
            {timeout: 10000}
            );
        }, 2000);
        }
    }

    mounted(){
        this.isAdmin = this.$route.query.admin != undefined; 
        this.init()
        .then(console.log)
        .catch(console.error);    
    }


}
