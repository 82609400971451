






































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch  } from "vue-property-decorator";
import axios from "axios";
import JsonViewer from "vue-json-viewer/ssr";
axios.defaults.baseURL = process.env.BASE_URL;
import "vue-json-viewer/style.css";
import { mapGetters } from "vuex";
import PaiementForm from "@/components/PaiementForm.vue";
import _ from 'lodash';

import PlotAscendanceOuverture from "@/components/scores/PlotAscendanceOuverture.vue";
import PosteMatchingReport from "@/components/scores/PosteMatchingReport.vue";
import ScoresComponent from "@/components/DeSiSectActiv/ScoresComponent.vue";

interface Plot {
  margin: number;
}

@Component({
  computed: mapGetters(["getUser"]),
  components: { JsonViewer, PlotAscendanceOuverture, PosteMatchingReport, PaiementForm, ScoresComponent},
})
export default class Scores extends Vue {
  qId = "";
  scores: any = {};
  infomsg = "";
  baseurl = process.env.BASE_URL;
  searchJson = "";
  jobs: any[] = [];
  company = "";
  referrer: any = {};
  getUser: any;
  metadata: any = null;

  personality_traits: any = null;
  
  emotionnel: any = null;
  motivationnel: any = null;
  sociabilite: any = null;

  influence: any = null;
  leadership: any = null;
  organisationnel: any = null;
  traits_complementaires: any = null;

  metiers: any= [];


  plot: Plot = {
    margin: 0.11
  };
  credits = ["P2T_FREE", "P2T_OPT1", "P2T_OPT2", "P2T_OPT3"]
  creditsText = {
    P2T_FREE: "option gratuite",
    P2T_OPT1: "option 1",
    P2T_OPT2: "option 2",
    P2T_OPT3: "option 3",
  }
  creditMax = "";
  get paiementMode(){
    return (this.$route.query.mode=='test')? 'test': 'production'
  }
  plots = {
    plot_ascendance_ouverture: 'PlotAscendanceOuverture',
    postes_details: 'PosteMatchingReport',
  }
  
  get isJobBoard(){
    return this.referrer.origin && this.referrer.origin._type=="JobBoardFormCredit"
  }
  
  getAge(identity) {

    if (identity.dob) {
      var today = new Date();

/*
      var birthDate = identity.dob
      var year = birthDate.slice(0, 4)
      var month = birthDate.slice(8, 10)
      var day = birthDate.slice(5, 7)

      var age = today.getFullYear() - year;
      var m = today.getMonth() - month;
      if (m < 0 || (m === 0 && today.getDate() < day)) {
          age--;
      }
*/

      const birthDate = new Date(identity.dob);


      const age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          return age - 1;
      }

      return age;  

    }    
    else {
      return "";
    }

}

  hasEnoughCreditsFor(ct){
    return this.credits.indexOf(ct)<=this.credits.indexOf(this.creditMax)
  }

  canBuyCredit(ct){
    return (~this.credits.indexOf(this.creditMax)) && this.credits.indexOf(ct) == this.credits.indexOf(this.creditMax) + 1
  }

  nCols(l, max){
    return Math.ceil(l.length/max)
  }

  columnize(l, max){
    let ans : any[] = [];
    let i = 0;
    while(i<=l.length){
      ans.push(l.slice(i, i+max))
      i = i+max;
    }
    return ans;
  }
  
  get allowedCredits(){
    const index = this.credits.indexOf(this.creditMax);
    if(!~index) return this.credits.slice(1)
    else {
      return this.credits.slice(index+1, index+2)
    }
  }

  mapCoords(percent) {
    return (
      (this.plot.margin * 100) / 2 + (1 - this.plot.margin) * percent + "%"
    );
  }
  create_index_str(i, j, max, padding){
    const k = i*max+j+1;
    const required_padding = padding - (Math.floor(Math.log10(k))+1);
    return ('0'.repeat(required_padding) + k +'- ')
  }
  get searchJsonRegex() {
    return new RegExp(this.searchJson || "", "i");
  }
  
  extractStructuredVal(e, sort){
    if(e){
      let arr = Object.entries(e);
      if(sort){
        const kv = sort.includes('key')?0:1;
        const ascdesc = sort.includes('desc')?-1:1;
        const sortf = e => e[kv] * ascdesc;
        arr = _.sortBy(arr, [sortf])
      }
      return arr;
    }else{
      return [];
    }
  }
  
  searchD(el, reg) {
    function f(e, i, path) {
      if (e == null || e == undefined) {
        return [e, false];
      } else if (e instanceof Array) {
        const ans = e.map((ee, ii) => f(ee, i + 1, path + "/[" + ii + "]"));
        if (ans.some(e => e[1])) return [ans.map(e => e[0]), true];
        else return [[], false];
      } else if (e.constructor == Object) {
        const acc = {};
        let ok = false;

        Object.keys(e).forEach(k => {
          if (reg.test(k)) {
            acc[k] = e[k];
            ok = true;
          } else {
            const ans = f(e[k], i + 1, path + "/" + k);

            if (ans[1]) {
              acc[k] = ans[0];
              ok = true;
            } else {
              return [{}, false];
            }
          }
        });

        return [acc, ok];
      } else {
        if (reg.test(e)) {
          return [e, true];
        } else {
          return [undefined, false];
        }
      }
    }
    return f(el, 0, "/")[0];
  }

  get dSearched() {
    if (!this.searchJson) {
      return this.scores;
    } else {
      return this.searchD(
        JSON.parse(JSON.stringify(this.scores)),
        this.searchJsonRegex
      );
    }
  }

  isReportSentence(v) {
    return v && v._type == "ReportSentence";
  }

  isArray(v) {
    return v instanceof Array;
  }

  formatPercent(v) {
    if (v == undefined || v == null) {
      return "NaN";
    } else {
      return (v * 100).toFixed(0) + " %";
    }
  }

  formatReportVal(v) {
    if (typeof v.val == "number") {
      if (v.is_percent) {
        return this.formatPercent(v.val);
      } else {
        return v.val;
      }
    } else {
      return v.val;
    }
  }
  
  handleSuccess(ct){
    console.log('PAIEMENT SUCCESS')
    axios.get('/api/pay-depouillement', {params: {id: this.qId, type: ct, mode: this.paiementMode}})
    .then(() => {
      this.$toast.success('vous avez acheté un crédit ' + ct)
      // this.fetchData()
      setTimeout(window.location.reload.bind(window.location), 1500)
    })
    .catch(err => {
      console.log(err)
    })
  }
  

  durations: any = [];
  autonomies: any = [];
  families: any = [];
  postes: any = [];

  currentDuration: any[] = [];
  currentAutonomy: any = [];
  currentFamily: any = [];
  currentSitension: boolean = false;

  dataDuration: any[] = [];
  dataAutonomy: any = [];
  dataFamily: any = [];
  dataSitension: any = [];

  postes_dict: any = {};

  var_phrase = "";

  datachanged(){

    var new_data:any[] = [];
    this.postes_dict = {};
    
    if (this.currentDuration.length || this.currentAutonomy.length || this.currentFamily.length){
      new_data = _.intersection(this.dataDuration, this.dataAutonomy, this.dataFamily)
    }else{
      new_data = this.postes;
    }

    if (this.currentSitension == true){
    new_data = _.union(new_data, this.dataSitension);
    }
    
    new_data.forEach(d => {
        this.postes_dict[d.name] = d;
    });
  }

  durationSelected(){
    this.dataDuration = [];
    if (this.currentDuration.length > 0){
      this.postes.forEach(p => {
        let added = false;
        p.durations.forEach(p_dur => {
          for (let i = 0 ; i< this.currentDuration.length; i++ ){
            let dur = this.currentDuration[i];
            if (p_dur == dur._id && added == false){
              this.dataDuration.push(p);
              added = true;
            }
          }
        })          
      })      
    }else{
      this.dataDuration = this.postes;
    }
    this.datachanged()
  }

  autonomySelected(){
    this.dataAutonomy = [];
    if (this.currentAutonomy.length > 0){
      this.postes.forEach(p => {
        let added = false;
        p.autonomies.forEach(p_aut => {
          for (let i = 0 ; i< this.currentAutonomy.length; i++ ){
              let auto = this.currentAutonomy[i];
              if (p_aut == auto._id  && added == false){
              this.dataAutonomy.push(p);
              added = true;
            }
          }
          })          
        })
    }else{
      this.dataAutonomy = this.postes;
    }
    this.datachanged()
  }


  familySelected(){
    this.dataFamily = [];

    if (this.currentFamily.length > 0){
      this.postes.forEach(p => {
          this.currentFamily.forEach(fam => {
            if (fam._id == p.family){
              this.dataFamily.push(p);
            }
          })          
      })
    }else{
      this.dataFamily = this.postes;
    }
    this.datachanged()
  }


  siTensionSelected(){
    this.dataSitension = [];
    this.postes.forEach(p => {
      if (this.currentSitension == p.JM_tension){
        this.dataSitension.push(p);
      }
    })
    this.datachanged()
  }

    @Watch("currentDuration")
    durationChanged() {
        this.durationSelected();
    }

    @Watch("currentAutonomy")
    autonomyChanged() {
        this.autonomySelected();
    }

    @Watch("currentFamily")
    familyChanged() {
        this.familySelected();
    }

    @Watch("currentSitension")
    siTensionChanged() {
        this.siTensionSelected();
    }


  fetchData() {
    axios
    .get("/api/depouillate", { params: { id: this.qId, limit: this.$route.query.limit } })
    .then(ans => {
      this.referrer = ans.data.referrer;
      this.scores = ans.data.data;
      this.scores.report.sections[0].items[1].val[1].val = (((this.scores.results.coherance_ascendance_ouverture.coherance_social_asc + this.scores.results.coherance_ascendance_ouverture.coherance_real_asc) / 2) + ((this.scores.results.coherance_ascendance_ouverture.coherance_social_ouv + this.scores.results.coherance_ascendance_ouverture.coherance_real_ouv) / 2)) / 2,
      this.jobs = ans.data.jobs;
      this.creditMax = ans.data.info.credits;
      this.company = ans.data.info.company;
      this.infomsg = "";
      this.metadata = ans.data.metadata;
      this.personality_traits = ans.data.personality_traits;

      this.emotionnel = this.personality_traits["Emotionnel"];
      this.motivationnel = this.personality_traits["Motivationnel"];
      this.sociabilite = this.personality_traits["Sociabilité"];

      this.influence = this.personality_traits["Influence"];
      this.leadership = this.personality_traits["Leadership"];
      this.organisationnel = this.personality_traits["Organisationnel"];

      this.traits_complementaires = this.personality_traits["Traits complémentaires"];

      this.metiers = ans.data.metiers;

    })
    .catch(err => {
      this.infomsg = err.response.data.message;
      this.$toast.error("Oops, le chargement a échoué !");
    });
  
    // get postes, durations, autonomies, families

  
            axios
            .get("/api/JobMatchingVariableData")
            .then(ans =>{

                    this.durations = ans.data.durations;
                    this.autonomies = ans.data.autonomies;
                    this.families = ans.data.families;    
                    this.postes = ans.data.jobs;      
                    this.dataDuration = ans.data.jobs;  
                    this.dataAutonomy = ans.data.jobs;  
                    this.dataFamily = ans.data.jobs;  
                    this.dataSitension = ans.data.jobs;  
                    ans.data.jobs.forEach(d => {
                        this.postes_dict[d.name] = d;
                    });
            })
  
  }

  fetchPhrase() {
    axios.get('/api/change-phrase')
      .then(response => {
        console.log(response.data); // Output the response data
        this.var_phrase = response.data.phrase;
      })
  }

  DeSIACTIV_q: any = null;
  mounted() {
    this.qId = (this.$route.query.id as string) || "";
    this.fetchData();

    if (this.qId && this.qId != ""){
        axios.get("/api/DeSIACTIV_from_premium", {params: {talentsId: this.qId}})
        .then(ans => {
          this.DeSIACTIV_q = ans.data['data'];
          console.log(this.DeSIACTIV_q);
        })      
    }
    this.fetchPhrase();

  }

  printDeSIActivityReport(){
    this.$toast.info("La création du rapport DeSI-Activités est en cours.");
    try {
        window.open("https://desi.alorem.fr/printDeSIActivityReport/?talentsId="+this.qId+"&id="+this.DeSIACTIV_q+"&metierList="+this.$store.state.metierList, "_blank");
    }
    catch(err) {
      this.$toast.error("Oops, Nous avons rencontré un probleme lors de la création du rapport !");
    }
  }

  printReport(){
    this.$toast.info("La création du rapport est en cours.");
    try {
        window.open("https://desi.alorem.fr/printReport/?id="+this.qId+"&jobSelected="+this.$store.state.jobSelected+"&duration="+this.currentDuration.map(e=> e._id)+"&autonomy="+this.currentAutonomy.map(e=> e._id)+"&family="+this.currentFamily.map(e => e._id)+"&siTension="+this.currentSitension, "_blank");
    }
    catch(err) {
      this.$toast.error("Oops, Nous avons rencontré un probleme lors de la création du rapport !");
    }   
  }

  printCertificat_SS() {
    this.$toast.info("La création du certificat est en cours.");
    try {
      window.open("https://desi.alorem.fr/print_Certificat_SS/?id="+this.qId +"&jobSelected="+this.$store.state.jobSelected, "_blank");
    }
    catch (err) {
      this.$toast.error("Oops, Nous avons rencontré un problème lors de la création du certificat !");
    }
  }
  
}
