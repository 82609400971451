

























































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {}
})

export default class NeuroscienceEvaluation extends Vue {

    currentItem: any = {name: '', scoresNames: '', total: 1, questions: [], id: ''};
    currentQuestion: any = {content : '', scoresValues: ''};
    allItems: any[] = [];

    currentTab = 1;
    scoresValues: any = [];

    private editContent(event: MouseEvent, num, type): void {
    const td = event.target as HTMLElement;
    const input = document.createElement('input');
    input.value = td.innerHTML;
    td.innerHTML = '';
    td.appendChild(input);
    input.focus();
    input.addEventListener('blur', () => {
      td.innerHTML = input.value;
      if (type == "content"){
        this.currentItem.questions[num].content = input.value;
      }
      else if (type == "scores"){
        this.currentItem.questions[num].scoresValues = input.value;
      }
      else if (type == "min"){
        this.currentSupportBrain.comments[num].min = input.value;
      }
      else if (type == "max"){
        this.currentSupportBrain.comments[num].max = input.value;
      }
      else if (type == "comment"){
        this.currentSupportBrain.comments[num].comment = input.value;
      }
    });
  }

    handleAddItem(){
        this.$bvModal.show('modal-item');
        this.currentItem = {name: '', scoresNames: '', total: 1, questions: [], id: ''};
        this.currentTab = 1;
    }

    continuerClicked(){
        this.scoresValues = [];
        if (this.currentItem.name == '' || this.currentItem.scoresNames == '' || this.currentItem.total == ''){
            this.$toast.error("Veuillez remplir tous les champs !")
        }
        else{
            if (this.currentItem.total <= 0){
                this.$toast.error("Le total doit etre > 0 !")
            }
            else{
                this.currentTab = 2;
                this.scoresValues = this.currentItem.scoresNames.split(",");
            }
        }
    }

    addQuestion(){
        this.currentItem.questions.push(this.currentQuestion);
        this.currentQuestion = {content : '', scoresValues: ''};
    }

    updateItem(p){
        this.currentItem = p;
        this.$bvModal.show('modal-item'); 
    }

    addItem(){
        
        this.currentTab = 1;        
        axios
        .post('/api/NeuroscienceItems', {
                id: this.currentItem.id,
                name: this.currentItem.name,
                scoresNames: this.currentItem.scoresNames,
                total: this.currentItem.total,
                questions: this.currentItem.questions 
                })
        .then(ans => {

            this.fetch_Items();
            this.currentItem = {name : '', scoresNames:'', total: 1, questions: [], id: ''};   
            this.$toast.success(ans.data);
        }).catch(err => {
            this.$toast.error('Une erreur est survenue')
        });

        this.$bvModal.hide('modal-item');     
    }


    removeItem(p){
        axios.delete('/api/NeuroscienceItems', {params: {id: p.id}})
        .then(ans => {
            this.$toast.success("Item supprimé avec succés !");
            this.fetch_Items();
        })
        .catch(err => {
            this.$toast.error('Une erreur est survenue'); 
        });
    }

    fetch_Items(){
        axios.get('/api/NeuroscienceItems')
        .then(ans => {
            this.allItems = ans.data;
            ans.data.forEach(element => {
                this.itemsOptions.push({'text': 'Total de : ' + element.name, 'value': element.id});
                this.itemsOptions.push({'text': 'Complementaire de : ' + element.name, 'value': element.id});
                this.itemsSupportOptions.push({'text': 'Total de : ' + element.name, 'value': element.id});
            });
        })
    }
    //////////////////////////////////////////////////////////////////////////////////

    currentDominantSupportiveBrain: any = {name: '', weight: 0, item: '', id: ''};
    itemsOptions: any[] = [];
    allIDominantSupportiveBrainsList : any[] = [];

    removeDominantSupportiveBrain(p){
        axios.delete('/api/DominantSupportiveBrain', {params: {id: p.id}})
        .then(ans => {
            this.$toast.success("Cerveau supprimé avec succés !");
            this.fetchDominantSupportiveBrain();
        })
        .catch(err => {
            this.$toast.error('Une erreur est survenue'); 
        });        
    }

    handleAddDominantSupportiveBrain(){
        this.$bvModal.show('modal-brain-1');
        this.currentDominantSupportiveBrain = {name: '', weight: 0, item: '', id: ''};        
    }

    addDominantSupportiveBrain(){
        
        axios
        .post('/api/DominantSupportiveBrain', {
                id: this.currentDominantSupportiveBrain.id,
                name: this.currentDominantSupportiveBrain.name,
                weight: this.currentDominantSupportiveBrain.weight,
                item: this.currentDominantSupportiveBrain.item,
                })
        .then(ans => {

            this.fetchDominantSupportiveBrain();
            this.currentDominantSupportiveBrain = {name: '', weight: 0, item: '', id: ''};      
            this.$toast.success(ans.data);

        }).catch(err => {
            this.$toast.error('Une erreur est survenue')
        });

        this.$bvModal.hide('modal-brain-1');           
    }

    fetchDominantSupportiveBrain(){

        axios.get('/api/DominantSupportiveBrain')
        .then(ans => {
            this.allIDominantSupportiveBrainsList = ans.data;
            ans.data.forEach(element => {
                this.brainsOptions.push({'text': element.name, 'value': element.id});
            })
            

        })

    }

    updateDominantSupportiveBrain(p){
        this.currentDominantSupportiveBrain = p;
        this.$bvModal.show('modal-brain-1'); 
    }

    //////////////////////////////////////////////////////////////////////////////////
    currentSupportBrain: any = {name: '', item: '', comments: [], id: ''};
    itemsSupportOptions: any[] = [];
    allISupportBrainsList: any[] = [];
    currentComment: any = {min: '', max: '', comment: ''};

    addComment(){
        this.currentSupportBrain.comments.push(this.currentComment);
        this.currentComment = {min: '', max: '', comment: ''};
    }

    handleAddSupportBrains(){
        this.$bvModal.show('modal-brain-2');
        this.currentSupportBrain = {name: '', item: '', comments: [], id: ''};                
    }

    fetchSupportBrains(){
        axios.get('/api/SupportBrain')
        .then(ans => {
            this.allISupportBrainsList = ans.data;
        })
    }

    addSupportBrain(){

        axios
        .post('/api/SupportBrain', {
                id: this.currentSupportBrain.id,
                name: this.currentSupportBrain.name,
                comments: this.currentSupportBrain.comments,
                item: this.currentSupportBrain.item,
                })
        .then(ans => {

            this.fetchSupportBrains();
            this.currentSupportBrain = {name: '', comments: [], item: '', id: ''};      
            this.$toast.success(ans.data);

        }).catch(err => {
            this.$toast.error('Une erreur est survenue')
        });

        this.$bvModal.hide('modal-brain-2'); 

    }
    
    updateSupportBrain(p){
        this.currentSupportBrain = p;
        this.$bvModal.show('modal-brain-2'); 
    }

    removeSupportBrain(p){
        axios.delete('/api/SupportBrain', {params: {id: p.id}})
        .then(ans => {
            this.$toast.success("Cerveau supprimé avec succés !");
            this.fetchSupportBrains();
        })
        .catch(err => {
            this.$toast.error('Une erreur est survenue'); 
        });        
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    currentCombinaison: any = {dominant: '', supportive: '', nature:'', typology: '', motivation:'', strongPt:'', weakness:'', threat:'', flourishing: '', job:'', id: ''};
    brainsOptions: any[] = [];
    allICombinaisonList: any[] = [];

    handleAddCombinaisonBrain(){
        this.$bvModal.show('modal-brain-3');
        this.currentCombinaison = {dominant: '', supportive: '', nature:'', typology: '', motivation:'', strongPt:'', weakness:'', threat:'', flourishing: '', job:'', id: ''};
    }

    addCombinaisonBrain(){

        axios
        .post('/api/CombinaisonBrain', {
                id: this.currentCombinaison.id,
                dominant: this.currentCombinaison.dominant, 
                supportive: this.currentCombinaison.supportive, 
                nature: this.currentCombinaison.nature, 
                typology: this.currentCombinaison.typology, 
                motivation: this.currentCombinaison.motivation, 
                strongPt: this.currentCombinaison.strongPt, 
                weakness: this.currentCombinaison.weakness, 
                threat: this.currentCombinaison.threat, 
                flourishing: this.currentCombinaison.flourishing, 
                job: this.currentCombinaison.job
                })
        .then(ans => {

            this.fetchCombinaisonBrains();
            this.currentCombinaison = {dominant: '', supportive: '', nature:'', typology: '', motivation:'', strongPt:'', weakness:'', threat:'', flourishing: '', job:'', id: ''};
            this.$toast.success(ans.data);

        }).catch(err => {
            this.$toast.error('Une erreur est survenue')
        });

        this.$bvModal.hide('modal-brain-3'); 

    }

    fetchCombinaisonBrains(){
        axios.get('/api/CombinaisonBrain')
        .then(ans => {
            this.allICombinaisonList = ans.data;
        })
    }

    updateCombinaisonBrains(c){
        this.$bvModal.show('modal-brain-3');
        this.currentCombinaison = c;
    }

    removeCombinaisonBrain(c){
        axios.delete('/api/CombinaisonBrain', {params: {id: c.id}})
        .then(ans => {
            this.$toast.success("Combinaison supprimée avec succés !");
            this.fetchCombinaisonBrains();
        })
        .catch(err => {
            this.$toast.error('Une erreur est survenue'); 
        });
    }

    mounted(){
        this.fetch_Items();
        this.fetchDominantSupportiveBrain();
        this.fetchSupportBrains();
        this.fetchCombinaisonBrains();
    }

}
