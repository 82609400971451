

































































import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: { ValidationProvider }
})
export default class FormElement extends Vue {
  @Inject("$validator") public $validator!: any;

  edit = false;

  @Prop()
  form!: any;

  @Prop()
  labelclass!: any;

  @Prop()
  formclass!: any;

  @Prop()
  field!: string;

  @Prop()
  fieldAgg!: string;

  @Prop()
  aggregation!: string;

  @Prop({ default: "" })
  helpTxt!: string;

  @Prop()
  emptyBtnText!: string;

  @Prop({ default: "3" })
  colclass!: any;

  @Prop({ default: "required" })
  rules!: any;

  @Prop()
  label!: string;

  toggleEdit() {
    this.edit = !this.edit;
  }

  fieldAggregated(ee) {
    return ee[this.fieldAgg];
  }

  mobilityGetProps(e, which) {
    return e.map(ee => {
      const k = Object.keys(ee).filter(k => k.includes(which))[0];
      return ee[k];
    });
  }

  @Watch("form.areasExperiences", { deep: true })
  arxpchgd(v) {
    if (v) {
      // console.log('looking for a preexisting something')
      // console.log("Years: "+JSON.stringify(this.form.savoirFaire.areasExperiencesYears))
      // console.log("new areaexp: "+JSON.stringify(v))
      this.form.areasExperiencesYears = v.map(e => {
        const preexist = this.form.areasExperiencesYears.filter(
          ee => ee.area == e.secteur
        );
        return {
          area: e.secteur,
          years: preexist.length > 0 ? preexist[0].years : 1
        };
      });
    }
  }

  aggMethod: any = {
    mobility: e => this.mobilityGetProps(e, "nom").join(", "),
    field: this.fieldAggregated,
    string: e => e
  };
}
