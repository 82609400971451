











































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL;


interface PersonalityFamily{
  id: string;
  family: string;
}

interface PersonalityTrait{
  id: string;
  name: string;
  family: PersonalityFamily;
}

interface Traits{
    personalityTrait: PersonalityTrait;
    handredComplement: boolean;
}

interface SS{
  id: string;
  ss: string;
  traitsList: Array<Traits>;
}

@Component({
  components: {}
})

export default class SoftSkillsEditor extends Vue {

/////////////////////////////////////////////////////////////////////////////////////
current_family: PersonalityFamily = {id: '', family: ''};
familiesList: Array<PersonalityFamily> = [];

handleAddFamily(){
    this.current_family = {id: '', family: ''};
    this.$bvModal.show('modal-family');
}

addFamily(){
    if (this.current_family.family == ""){
        this.$toast.error('Veuillez introduire la famille !')
    }
    else{
        axios
        .post("/api/traits_personality-family", {}, {params: {id: this.current_family.id, family: this.current_family.family}})
        .then(ans => {
            this.$toast.success(ans.data);
            this.fetch_families();
            this.fetch_ss();
            this.fetch_traits();
        })
        .catch(err => {
            this.$toast.error(err.response.data);
        })
                
        this.closeModal('family');
    }
}

removeFamily(fam){
    //remove from database
    axios.delete("/api/traits_personality-family", {params: {id: fam.id, family: fam.family}})
    .then(ans => {
        this.fetch_families();
        this.fetch_traits();
        this.fetch_ss();
        this.$toast.success('Famille supprimée');
    })
    .catch(err => {
       this.$toast.error('Une erreur est survenue'); 
    });
}

updateFamily(fam){
    this.current_family = fam;
    this.$bvModal.show('modal-family');
}

fetch_families(){
    return axios
    .get("/api/traits_personality-family")
    .then(ans => {
        this.familiesList = ans.data;
    })
}


/////////////////////////////////////////////////////////////////////////////////////
current_trait: PersonalityTrait = {
    id: '',
    name : '',
    family: {id: '', family: ''},
}
traitList: Array<PersonalityTrait> = [];

handleAddTrait(){
    this.$bvModal.show('modal-trait');
    this.current_trait = {
        id: '',
        name : '',
        family: {id: '', family: ''}
    }

}


addTrait(){

    if (this.current_trait.name == "" || this.current_trait.family.family == ""){
        this.$toast.error('Veuillez inserer tous les champs !')
    }
    else{
        axios
        .post("/api/traits_personality", {}, {params: {id: this.current_trait.id, name: this.current_trait.name, family: this.current_trait.family}})
        .then(ans => {
            this.$toast.success(ans.data);
            this.fetch_traits();
            this.fetch_ss();
        })
        .catch(err => {
            this.$toast.error(err.response.data);
        })
                
        this.closeModal('trait');
    }
}

fetch_traits(){
    return axios
    .get("/api/traits_personality")
    .then(ans => {
        this.traitList = ans.data;
    })
}


removeTrait(trait){
    //remove from database
    axios.delete("/api/traits_personality", {params: {id: trait.id, name: trait.name, family: trait.family}})
    .then(ans => {
        this.fetch_traits();
        this.fetch_ss();
        this.$toast.success('Trait supprimé');
    })
    .catch(err => {
       this.$toast.error('Une erreur est survenue'); 
    });
}

updateTrait(trait){
    this.current_trait = trait;
    this.$bvModal.show('modal-trait');
}


//////////////////////////////////////////////////////////////////////////////////////
currentSS: SS = {
  id: "",
  ss: "",
  traitsList: [],
};

ssList: SS[] = [];

currentVal: PersonalityTrait = {
        id: '',
        name : '',
        family: {id: '', family: ''}
    };

addVal(){
    if(this.currentVal.name != ""){
        
        this.currentSS.traitsList.push({personalityTrait: this.currentVal, handredComplement: false})
        this.currentVal = {
            id: '',
            name : '',
            family: {id: '', family: ''}
            };
    }
}

removeVal(e){
    this.currentSS.traitsList = this.currentSS.traitsList.filter(ee => ee.personalityTrait.id != e.personalityTrait.id)
}

handleAddSS(){  
    this.$bvModal.show('modal-ss');
    this.currentSS = {
        id: '',
        ss : '',
        traitsList: []
    }
}

addSS(){

    console.log(this.currentSS)

    if (this.currentSS.ss == ""){
        this.$toast.error('Veuillez inserer le nom du soft-skill !')
    }
    else{
        axios
        .post("/api/soft-skill", {}, {params: {id: this.currentSS.id, ss: this.currentSS.ss, traitsList: this.currentSS.traitsList, traitsListNBR: this.currentSS.traitsList.length}})
        .then(ans => {
            this.$toast.success(ans.data);
            this.fetch_ss();
        })
        .catch(err => {
            this.$toast.error(err.response.data);
        })
                
        this.closeModal('ss');
    }


}

fetch_ss(){
    return axios
    .get("/api/soft-skill")
    .then(ans => {
        this.ssList = ans.data;
    })
}

updateSS(ss){

    this.currentSS = ss;
    this.$bvModal.show('modal-ss');

}

removeSS(ss){

}


/////////////////////////////////////////////////////////////////////////////////////////

closeModal(modalName){
    console.log('modal-'+modalName);
    this.$bvModal.hide('modal-'+modalName);
}

mounted(){
    this.fetch_families();
    this.fetch_traits();
    this.fetch_ss();
}

}

