import { render, staticRenderFns } from "./FormElement.vue?vue&type=template&id=4e787b28&scoped=true&"
import script from "./FormElement.vue?vue&type=script&lang=ts&"
export * from "./FormElement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e787b28",
  null
  
)

export default component.exports