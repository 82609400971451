


















































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import QuestionnaireExplorer from "@/components/QuestionnaireExplorer.vue";
import EngineExplorer from "@/components/EngineExplorer.vue";
import UserManagement from "@/components/UserManagement.vue";
import CreditsViewer from "@/components/CreditsViewer.vue";
import JobMatchingEditor from "@/components/JobMatchingEditor.vue";

import { mapGetters } from "vuex";

axios.defaults.baseURL = process.env.BASE_URL;

@Component({
  components: {
    QuestionnaireExplorer,
    EngineExplorer,
    UserManagement,
    CreditsViewer,
    JobMatchingEditor,
  },
  computed: mapGetters(["getUser"])
})
export default class DashboardClient extends Vue {}
