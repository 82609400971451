



































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import { JobMatching } from "@/types";
import { mapGetters } from "vuex";
import _ from "lodash";

axios.defaults.baseURL = process.env.BASE_URL;

interface JobEdition{
  data: JobMatching;
  isNew: boolean;
  saved: boolean;
}

interface SS{
  _id: string;
  ss: string;
}


@Component({components: {}, computed: mapGetters(["getUser"])})
export default class JobMatchingEditor extends Vue {
  @Prop({ default: false })
  displayHelp!: boolean;

  showOnlySameCompany = false;

  jobs : JobMatching[] = [];
  ss: SS[] = [];
  currentVal: string = "";
  getUser: any;

    families: string[] = [];
    durations: string[] = [];
    autonomies: string[] = [];

    currentDuration: string[] = [];
    currentAutonomy: string[] = [];

    familiesRECORDS = {};
    durationsRECORDS = {};
    autonomiesRECORDS = {};

    ssNames = {};

    get_SS_by_id(id){
      return this.ssNames[id];
    }

  currentJob: JobEdition = {
    data: {
      id: "",
      name: "",
      creator: "",
      family: "",
      durations: [],
      autonomies: [],
      JM_tension: false,
      val: [],
      created: new Date(),
      modified: new Date(),
    },
    isNew: true,
    saved: true,
  };

  get ss_opts(){
    const defaultval: any[] = [{text: "Ajouter une compétence...", value: '', disabled: true}];
    return defaultval.concat(this.ss.map(e => {
      return {
        text: e.ss.replace('- ', ''),
        value: e._id,
        disabled: this.currentJob.data.val.filter(
		ee => this.get_SS_by_id(ee.name) == e.ss
		).length
      }
    }))
  }


  resetJob(){

    this.newJob();
  }

  deleteJob(){
    axios.delete('/api/job-matching', {params: {id:this.currentJob.data.id}})
    .then(() => {
      this.$toast.success('poste matching supprimé!')
      this.fetchData();
      this.newJob();
    })
    .catch(console.log)
  }
  
  removeVal(e){
    this.currentJob.data.val = this.currentJob.data.val.filter(ee => ee.name != e.name)
  }

  addVal(){
    if(this.currentVal){
      this.currentJob.data.val.push({name: this.currentVal, val: 0})
      this.currentVal='';
    }
  }
  
  newJob(){

    this.currentJob = {
      data: {
        id: "",
        name: "",
        creator: "",
        family: "",
        durations: [],
        autonomies: [],
        JM_tension: false,
        val: [],
        created: new Date(),
        modified: new Date(),
      },
      isNew: true,
      saved: true,
    }
    this.currentVal = "";
    this.currentDuration= [];
    this.currentAutonomy=[];

  }

  setCurrentJob(e){

    this.currentJob = {
      data: e,
      isNew: false,
      saved: true,
    }

    this.currentAutonomy = this.currentJob.data.autonomies
    this.currentDuration = this.currentJob.data.durations

    console.log(this.currentJob.data)

  }

  @Watch('currentJob.data', {deep: true})
  cjchgd(){
    this.currentJob.saved = false;
  }

  saveJob(){

    this.currentJob.data.durations = this.currentDuration
    this.currentJob.data.autonomies = this.currentAutonomy
    
    axios.post('/api/job-matching', {data: this.currentJob.data, isNew: this.currentJob.isNew})
    .then(() => {
      this.fetchJobs().then(() => {
        this.newJob();
        this.fetchJobs();
        this.$toast.success('Métier sauvegardé avec succès!')
      }).catch((err) => {
        console.log(err.response)
        this.$toast.error('Une erreur est survenue')
      });
      
    })
    .catch(() => {
      this.$toast.error('Une erreur est survenue')
    })
  }


  get families_opts(){
    
    const defaultval: any[] = [{text:"Sélectionner une famille", value: '', disabled: true}];
    return defaultval.concat(this.families.map( e  => {
      return {
        text: this.familiesRECORDS[e],
        value: e,
      }
    })).sort((a, b) => a.text.localeCompare(b.text))
  }

  get durations_opts(){

    const defaultval: any[] = [{text:"Sélectionner la durée d'études requise pour ce poste", value: "", disabled: true}];
    return defaultval.concat(this.durations.map( e => {
      return {
        text: this.durationsRECORDS[e],
        value: e,
      }
    }))

  }

  get autonomies_opts(){

    const defaultval: any[] = [{text:"Sélectionner l'autonomie", value: "", disabled: true}];
    
    return defaultval.concat(this.autonomies.map( e => {
      return {
        text: this.autonomiesRECORDS[e],
        value: e,
      }
    }))

  }

    fetchFamiliesDurationsAutonomy(){

        axios.get('/api/JobMatchingVariableData')
        .then(resp => {

        for (let i = 0; i < resp.data.families.length; i++){
            this.familiesRECORDS[resp.data.families[i]._id] = resp.data.families[i].familles_poste
        }

        for (let i = 0; i < resp.data.durations.length; i++){
            this.durationsRECORDS[resp.data.durations[i]._id] = resp.data.durations[i].duree_etudes
        }

        for (let i = 0; i < resp.data.autonomies.length; i++){
            this.autonomiesRECORDS[resp.data.autonomies[i]._id] = resp.data.autonomies[i].autonomie
        }


        this.families = resp.data.families.map(e => e._id)
        this.durations = resp.data.durations.map(e => e._id)
        this.autonomies = resp.data.autonomies.map(e => e._id)
        

        })
        .catch(console.log)

  }
  
  fetchJobs(){
    return axios.get('/api/job-matching')
    .then(resp => {

      this.jobs = _.sortBy(resp.data, [e => e.company=="Alorem", e => e.name])

    })
    .catch(console.log)
  }
  
  fetchData(){
    axios.get('/api/ss')
    .then(resp => {

      this.ss = resp.data.map(e => e);
      for (let i = 0; i < this.ss.length; i++){
        this.ssNames[this.ss[i]._id] = this.ss[i].ss;
      }
      console.log('haha');
      console.log(this.ssNames);
    })
    .catch(console.log)
    this.fetchJobs();
    this.fetchFamiliesDurationsAutonomy();
  }
  
  mounted(){
    this.fetchData();
  }

}
