



































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

interface Item {
  value: string;
  selected: boolean;
}

@Component
export default class ListInput extends Vue {
  @Prop({ default: () => [] })
  private list!: Array<string>;

  @Prop()
  private value!: Array<string>;

  @Prop({ default: true })
  private selectable!: boolean;

  @Prop({ default: true })
  private searchable!: boolean;

  @Prop({ default: true })
  private editable!: boolean;

  items: Array<Item> = [];
  newItem = "";
  viewOnlySelected = false;

  get newItemIsValid() {
    return this.newItem && !~this.items.map(e => e.value).indexOf(this.newItem);
  }

  get newItemRegex() {
    return new RegExp(
      this.newItem ? this.newItem.split(" ").join(".*") : "",
      "i"
    );
  }

  addItem() {
    this.items.push({ value: this.newItem, selected: true });
    this.newItem = "";
  }

  remove(val) {
    this.items = this.items.filter(e => e.value != val);
  }

  click(e) {
    e.selected = !e.selected;
  }

  get inputText() {
    return this.selectedItems.join("\n");
  }

  @Watch("value")
  valChanged(v) {
    // console.log('Set listinput val to: ' + JSON.stringify(v))
    v.forEach(e => {
      const i = this.items.map(ee => ee.value).indexOf(e);
      if (i == -1) {
        this.items.push({ value: e, selected: true });
      } else {
        this.items[i].selected = true;
      }
    });
    // this.items = this.items.filter(e => !~v.indexOf(e.value))
  }

  get selectedItems() {
    return this.items.filter(e => e.selected).map(e => e.value);
  }

  @Watch("selectedItems")
  selectedChanged(v) {
    if (v) {
      // console.log('list input emits:')
      // console.log(this.list)
      // console.log(v)
      this.$emit("input", v);
    }
  }

  setItems(l) {
    l.forEach(e => {
      const i = this.items.map(ee => ee.value).indexOf(e);
      if (i == -1) {
        this.items.push({ value: e, selected: false });
      }
    });
  }

  @Watch("list")
  listChanged(v) {
    this.setItems(v);
  }

  mounted() {
    if (this.list) {
      this.setItems(this.list);
    }
    if (this.value && this.value.length) {
      this.valChanged(this.value);
    }
  }
}
