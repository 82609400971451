




























import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { Record } from "@/types";

axios.defaults.baseURL = process.env.BASE_URL;

@Component
export default class QuestionnaireDownloader extends Vue {
  questionnaires: Array<Record> | null = null;

  @Prop()
  a: Record | undefined;
  private download(e) {
    window.open(process.env.BASE_URL + "download/?id=" + e.id, "_blank");
  }

  private reload(e) {
    e.meta.reloadingTxt = 3;
    e.fileReady = false;
    // console.log('RELOAD');
    return axios
      .get("/api/questionnaire-generate-file", { params: { id: e.id } })
      .then(ans => {
        console.log(ans);
        const interf = () => {
          // console.log('interf');
          window.setTimeout(() => {
            console.log("checking if " + e.id + " is available...");
            axios
              .get("/api/questionnaire-file-is-ready", { params: { id: e.id } })
              .then(ans => {
                console.log(ans);
                if (ans.data == true) {
                  console.log("ready");
                  e.fileReady = true;
                  e.meta.reloadingTxt = false;
                } else {
                  e.meta.reloadingTxt = ((e.meta.reloadingTxt || 0) + 1) % 4;
                  interf();
                }
              })
              .catch(console.log);
          }, 1000);
        };
        interf();
      })
      .catch(console.log);
  }
}
